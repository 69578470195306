(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('AreaRegional', AreaRegional);

    AreaRegional.$inject = ['$resource'];

    function AreaRegional ($resource) {
        var resourceUrl =  'api/arearegional/:id';
        var urlResourceEstabRegional = 'api/arearegional/regional/:regionalId';        
        var urlResourceTodosEstabAtivosRegional = 'api/arearegional/ativos/regional/:regionalId';

        var resourceprincipal =  $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        function getQueryResurce(url){
            return $resource(url, {}, {
                    'query': {method: 'GET', isArray: true}
                }).query;
        };

        return {
            query : resourceprincipal.query,
            save : resourceprincipal.save,
            update : resourceprincipal.update,
            get : resourceprincipal.get,
            delete : resourceprincipal.delete,            
            queryPorRegional : getQueryResurce(urlResourceEstabRegional),
            queryTodosAtivosPorRegional : getQueryResurce(urlResourceTodosEstabAtivosRegional)
        };
    }
})();
