(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('GrupoLancamentoDialogController', GrupoLancamentoDialogController);

    GrupoLancamentoDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', '$uibModalInstance', 'entity', 'GrupoLancamento'];

    function GrupoLancamentoDialogController ($timeout, $scope, $state, $stateParams, $uibModalInstance, entity, GrupoLancamento) {
        var vm = this;

        vm.grupolancamento = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tiposOperacao = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        GrupoLancamento.queryTipos().$promise.then(function(res){
            vm.tiposOperacao = _.orderBy(res, ['nome'], ['asc']);
            for (var k=0; k < vm.tiposOperacao.length; k++){
                if (vm.grupolancamento.tipoOperacaoId && vm.grupolancamento.tipoOperacaoId === vm.tiposOperacao[k].id){
                    vm.grupolancamento.tipoOperacao = vm.tiposOperacao[k];
                }
            }
        });        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.grupolancamento.id !== null) {
                GrupoLancamento.update(vm.grupolancamento, onSaveSuccess, onSaveError);
            } else {
                GrupoLancamento.save(vm.grupolancamento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:GrupoLancamentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
