(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('VendaDialogController', VendaDialogController);

    VendaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$http', '$cookies', '$uibModalInstance', 
       'entity', 'Concurso', 'AlertService', 'DistribuicaoBilhetes'];

    function VendaDialogController ($timeout, $scope, $stateParams,  $http, $cookies, $uibModalInstance, 
       entity, Concurso, AlertService, DistribuicaoBilhetes) {
        var vm = this;
        vm.loading = true;
        vm.regional = {id: entity.regionalId }
        vm.lotevenda = entity;
        vm.lotevenda.lista = [];        
        vm.formlote = {};
        vm.concursos = [];
        vm.alerts = [];        
        vm.clear = clear;
        vm.save = save;   
        vm.changeconcurso= changeconcurso;

        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
          });
        };
        positionFirstInput();
        Concurso.querynaosinc().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0){
                selecionaConcursoPadrao();
                changeconcurso();
            }
        });

        function changeconcurso(){
            salvaConncursoCookie();
            vm.lotevenda.bilheteInicial = null;
            vm.lotevenda.bilheteFinal = null;
            vm.loading = false;                    
        } 
 

       function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            DistribuicaoBilhetes.distribuiEstabelecimento({id: null,                      
                estabelecimentoId : vm.lotevenda.estabelecimentoId,
                concursoId: vm.lotevenda.concurso.id,
                bilheteInicial : vm.lotevenda.bilheteInicial,
                bilheteFinal: vm.lotevenda.bilheteFinal,
                motivoDistribuicao : 'X' // MOTIVO_DISTRIBUICAO_REGIONAL
            }).$promise.then( onSaveSuccess).catch(onSaveError);
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            AlertService.success('Lote de venda criado ');
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Houve um erro na criação do lote de venda de bilhetes!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.bilheteinvalido'){
                    msg = 'Bilhete inválido: ' + errorparam; 
                } else if (errorkey === 'error.periodoinvalido'){
                    msg = 'Faixa não disponível! Verifique se existem bilhetes entregues a outra regional.'; 
                } else if (errorkey === 'error.sempermissao'){
                    msg = 'O usuário não tem permissão para alterar todos os bilhetes deste intervalo.';                    
                } else if (errorkey === 'error.bilhetejavalidado'){
                    msg = 'Existem bilhetes validados no intervalo informado.';                    
                }  else if (errorkey === 'error.bilhetenaocorrespondeconcurso'){
                    msg = 'Bilhete ' + errorparam + ' não corresponde ao concurso selecionado.'; 
                } else if (errorkey === 'error.faixapertenceoutrolote'){
                    msg = 'Parte dos bilhetes já atribuidos a uma regional diferente deste estabelecimento.';
                } else if (errorkey === 'error.loteOutraRegional'){
                    msg = 'Parte dos bilhetes já atribuidos a uma outra regional.';
                }
            } 
            AlertService.error(msg);      
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.lotevenda.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.lotevenda.concurso);
        }             
    }
})();
