(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('SorteioDiarioDialogController', SorteioDiarioDialogController);

    SorteioDiarioDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'dados'];

    function SorteioDiarioDialogController ($timeout, $scope, $stateParams, $uibModalInstance, dados) {
        var vm = this;
        vm.elementos = dados.elementos;
        vm.concurso = dados.concurso;
        vm.clear = clear;
        vm.tuplas = [];        
        vm.dataAtual = nowToString();
        console.log("DADOS %o", dados);
        particao();

        function nowToString(){
            var hoje = new Date();
            return hoje.getDate() +'/'+(hoje.getMonth()+1)+'/'+hoje.getFullYear();
        }

        function particao(){
           for(var a=0; a < vm.elementos.length; a++){
               var el = vm.elementos[a];
               el.anim1 = Array.from('000');
               el.anim2 = Array.from('00000000');
               el.anim3 = Array.from('00');
               el.separador = '-';    
               el.mostrarEstabelecimento = false;
               el.mostrarNumeroBilhete  = false;
               if(a%3==0) {vm.tuplas.push({'0':null, '1':null, '2':null});}
               //var idxpont = el.identificacao.indexOf('.');
               var idxtrac = el.identificacao.indexOf('-');
               el.charpreid = Array.from(el.identificacao.substring(0,3));
               el.charmidid = Array.from(el.identificacao.substring(3,idxtrac));
               el.charfimid = Array.from(el.identificacao.substring(idxtrac+1,el.length));
               vm.tuplas[Math.floor(a/3)][a%3] = el;
           }
        }

        vm.startSorteio = function(){
            var timebihete = 5000;  
            var timedigit = 100;            
            for(var a=0; a < vm.elementos.length; a++){
               var el = vm.elementos[a];          
               var realizaAnimacao = function(j, arrayorigem, arraydestino){
                    $timeout(function(){
                        arraydestino[j] = (9-arrayorigem[j]);
                        $timeout(function(){
                            arraydestino[j] = Math.floor(Math.random()*10);
                            $timeout(function(){
                                arraydestino[j] = arrayorigem[j];
                            },timedigit);
                        },timedigit);
                    }, j*timedigit);                   
               };
               (function(i, e){
                    $timeout(function(){
                        e.mostrarNumeroBilhete = true;
                        for(var b=0; b < el.anim1.length; b++){
                            e.anim1[b] = e.charpreid[b];
                        }
                        for(var b=0; b < el.anim2.length; b++){
                            realizaAnimacao(b, e.charmidid, e.anim2);
                        }
                        for(var b=0; b < el.anim3.length; b++){
                            realizaAnimacao(b, e.charfimid, e.anim3);                   
                        }
                        $timeout(function(){
                            e.mostrarEstabelecimento = true;
                        }, (timebihete - 1000) );
                  }, i*timebihete);
               })(a, el);
           }           
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
