(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('DistribuicaoBilhetes', DistribuicaoBilhetes);

        DistribuicaoBilhetes.$inject = ['$resource'];

    function DistribuicaoBilhetes ($resource) {
        var urlResourceDistribuicaoBilheteNumeracao = 'api/distribuicaobilhetes/bilhete/:concursoId/:numero'; 
        var urlResourceDistribuicaoBilhetes = 'api/distribuicaobilhetes/:concursoId/:regionalId';
        var urlResourceLotesRegionais = 'api/distribuicaobilhetes/loteregionais';
        var urlResourceLotesEstabelecimentos = 'api/distribuicaobilhetes/loteestabelecimentos';        
        var urlResourceTotalizacaoTodasRegionais = 'api/distribuicaobilhetes/loteregionais-totalizacao/:concursoId';
        var urlResourceTotalizacaoRegional = 'api/distribuicaobilhetes/loteregionais-totalizacao/:concursoId/:regionalId';
        var urlResourceTendenciaTotalizacaoRegional = 'api/distribuicaobilhetes/loteregionais-tendencia/:regionalId';
        var urlResourceTotalizacaoEstabelecimentos = 'api/distribuicaobilhetes/loteestabelecimentos-totalizacao/:concursoId/:regionalId/:areaId';
        var urlResourceDistribuicaoRegional = 'api/distribuicaobilhetes/loteregionais/:concursoId/:regionalId';
        var urlResourceDistribuicaoDisponivelRegional = 'api/distribuicaobilhetes/loteestabelecimentos-disponiveis/:concursoId/:regionalId';        
        var urlResourceDistribuicaoEstabelecimentoPorRegional = "api/distribuicaobilhetes/loteestabelecimentos-reg/:concursoId/:regionalId/:areaId";
        var urlResourceDistribuicaoEstabelecimentoPorEstabelecimento = "api/distribuicaobilhetes/loteestabelecimentos-est/:concursoId/:estabelecimentoId";
   
        var resourceLoteRegional = $resource(urlResourceLotesRegionais, {}, {
            'query': {method: 'GET', isArray: true},
            'save':  {method:'POST', isArray: false}
        });
        var resourceLoteEstabelecimento = $resource(urlResourceLotesEstabelecimentos, {}, {
            'query': {method: 'GET', isArray: true},
            'save':  {method:'POST', isArray: false}
        });

        function getQueryResurce(url){
            return $resource(url, {}, {
                    'query': {method: 'GET', isArray: true}
                }).query;
        } 
        
        return {
            queryLogDistribuicaoBilhetePorNumeracao: getQueryResurce(urlResourceDistribuicaoBilheteNumeracao),
            queryLogDistribuicaoBilhetes: getQueryResurce(urlResourceDistribuicaoBilhetes),            
            queryRegional : resourceLoteRegional.query,
            distribuiRegional : resourceLoteRegional.save,
            queryEstabelecimento : resourceLoteEstabelecimento.query,
            distribuiEstabelecimento : resourceLoteEstabelecimento.save,
            queryDistribuicaoRegional: getQueryResurce(urlResourceDistribuicaoRegional),             
            queryDistribuicaoDisponivel: getQueryResurce(urlResourceDistribuicaoDisponivelRegional),                                                       
            queryDistribuicaoEstabelecimentoPorRegional: getQueryResurce(urlResourceDistribuicaoEstabelecimentoPorRegional),
            queryDistribuicaoEstabelecimentoPorEstabelecimento: getQueryResurce(urlResourceDistribuicaoEstabelecimentoPorEstabelecimento),
            queryTotalizacaoRegional: $resource(urlResourceTotalizacaoRegional, {}, {
                                        'query': {method: 'GET', isArray: false}
                                    }).query,   
            queryTotalizacaoTodasRegional: $resource( urlResourceTotalizacaoTodasRegionais, {}, {
                                        'query': {method: 'GET', isArray: true}
                                    }).query,
            queryTendenciaTotalizacaoRegional: $resource(urlResourceTendenciaTotalizacaoRegional, {}, {
                                        'query': {method: 'GET', isArray: true}
                                    }).query,                                       
            queryTotalizacaoEstabelecimentos: $resource( urlResourceTotalizacaoEstabelecimentos, {}, {
                                        'query': {method: 'GET', isArray: false}
                                    }).query    
        }

    }
})();
