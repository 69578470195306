(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('AreaRegionalDeleteController',AreaRegionalDeleteController);

    AreaRegionalDeleteController.$inject = ['$rootScope', 'AlertService','$uibModalInstance', 'entity', 'AreaRegional'];

    function AreaRegionalDeleteController($rootScope, AlertService, $uibModalInstance, entity, AreaRegional) {
        var vm = this;

        vm.arearegional = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AreaRegional.delete({id: id},
                function () {
                    $rootScope.$broadcast('areaRegionalUpdate');                    
                    AlertService.success('Área Regional excluida com sucesso');    
                    $uibModalInstance.close(true);
                },
                function(){
                    AlertService.error("Não foi possível excluir a área regional!\nVerifique se não existem estabelecimentos associados.");
                });
        }        
    }
})();
