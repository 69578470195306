(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('EstabelecimentoDetailController', EstabelecimentoDetailController);

    EstabelecimentoDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', '$http',
     'entity', 'Concurso', 'Estabelecimento', 'DistribuicaoBilhetes', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function EstabelecimentoDetailController($scope, $rootScope, $state, $stateParams, previousState, $http, 
             entity, Concurso, Estabelecimento, DistribuicaoBilhetes, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.estabelecimento = entity;
        vm.concursos = [];
        vm.filtro = {concurso: null};
        vm.lotebilhetes = [];        
        vm.previousState = previousState.name;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'loteRegionalId';
        vm.reset = reset;
        vm.reverse = true;

        var unsubscribe = $rootScope.$on('sorteioApp:estabelecimentoUpdate', function(event, result) {
            vm.estabelecimento = result;
        });
        $scope.$on('$destroy', unsubscribe);

        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0 && vm.filtro.concurso == null){
                vm.filtro.concurso=vm.concursos[0];
                changeconcurso();
            }
        });

        function changeconcurso(){
            loadAll ();
        }       

        function loadAll () {
            if (vm.concursos.length == 1){
                vm.filtro.concurso = vm.concursos[0];
            }            
            DistribuicaoBilhetes.queryDistribuicaoEstabelecimentoPorEstabelecimento({
                concursoId: vm.filtro.concurso.id,
                estabelecimentoId: vm.estabelecimento.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'loteRegionalId') {
                    result.push('loteRegionalId');
                }
                return result;
            }

            function onSuccess(data, headers) {
                console.log("DATA %o %o", data ,headers);
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;     
                vm.lotebilhetes = data;       
            }

            function onError(error) {
                log.console(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.filtro.concurso = null;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            console.log('STATE %o', $state);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro,
                id : vm.estabelecimento.id
            });
        }          
    }
})();
