(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteRegionalDialogController', LoteRegionalDialogController);

    LoteRegionalDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state','$uibModalInstance', 
        'Concurso', 'Regional', 'LoteRegional', 'AlertService',  'idconcurso', 'idregional'];

    function LoteRegionalDialogController ($timeout, $scope, $stateParams,  $http, $state,
         $uibModalInstance,
       Concurso, Regional, LoteRegional, AlertService, idconcurso, idregional) {
        var vm = this;

        vm.tipos = [];
        vm.regionais = [];
        vm.concursos = [];

        vm.lotedistribuicao = {concurso: null, regional: null, bilheteInicial: null, bilheteFinal: null};
        vm.updatelist = updatelist;
        vm.quantidadeTotal = 0;


        //vm.alerts = [];
        vm.clear = clear;
        vm.save = save;
        //vm.addLote = addLote;

        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = res;
            for(var a=0; a < vm.regionais.length; a++){
                if (idregional == vm.regionais[a].id){
                    vm.lotedistribuicao.regional = vm.regionais[a];
                    break;                    
                }  
            }
        });
        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            for(var a=0; a < vm.concursos.length; a++){
                if (idconcurso == vm.concursos[a].id){
                    vm.lotedistribuicao.concurso = vm.concursos[a];
                    break;
                }  
            }
            if(vm.concursos.length > 0 && !vm.lotedistribuicao.concurso){
                vm.lotedistribuicao.concurso=vm.concursos[0];
            }
        });

        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
          });
        };
        positionFirstInput();        

        function updatelist(){
            vm.quantidadeTotal = 0;
            if(vm.lotedistribuicao.bilheteInicial && vm.lotedistribuicao.bilheteFinal){
                var bilini = vm.lotedistribuicao.bilheteInicial.replace('-','');
                var bilfim = vm.lotedistribuicao.bilheteFinal.replace('-','');
                if (bilini.length == 13 && bilfim.length == 13){
                    var numini = bilini.slice(4,11);
                    var numfim = bilfim.slice(4,11);
                    if (bilini.slice(3,4) == vm.lotedistribuicao.concurso.tipo.id && 
                        bilfim.slice(3,4) == vm.lotedistribuicao.concurso.tipo.id){
                        vm.quantidadeTotal += (numfim-numini +1);
                    }
                }
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
               //if (vm.lotedistribuicao.quantidade != vm.quantidadeTotal){
               //   AlertService.error('A quantidade des bilhetes é diferente da indicada!');    
               //} else {
                LoteRegional.save({id: null,
                        regionalId : (vm.lotedistribuicao.regional)? vm.lotedistribuicao.regional.id : null,
                        concursoId: (vm.lotedistribuicao.concurso)? vm.lotedistribuicao.concurso.id : null,
                        quantidade : vm.lotedistribuicao.quantidade,
                        bilheteInicial : vm.lotedistribuicao.bilheteInicial,
                        bilheteFinal : vm.lotedistribuicao.bilheteFinal,
                        }, onSaveSuccess, onSaveError);
                //}
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            
            vm.isSaving = false;
            AlertService.success('Lote de bilhetes criado com sucesso');
            console.log("RES %o", result)
            $uibModalInstance.close(result);
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Problema na criação do lote!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.bilheteinvalido'){
                    msg = 'Bilhete inválido: ' + errorparam; 
                } else if (errorkey === 'error.bilhetenaocorrespondeconcurso'){
                    msg = 'Bilhete ' + errorparam + ' não corresponde ao concurso selecionado.'; 
                } else if (errorkey === 'error.faixapertenceoutrolote'){
                    msg = 'Uma das faixas possui interseção com o lote: ' + errorparam;  
                } else if (errorkey === 'error.loteinvalido'){
                    msg = 'Lote inválido: ' + errorparam;
                } else if (errorkey === 'error.valorloteinvalido'){
                    msg = 'Lote com valor diferente do indicado: ' + errorparam;                    
                }
            } 
            AlertService.error(msg);
        }

    }
})();
