(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ConsultaStatusBilhetesDistribuidosController', ConsultaStatusBilhetesDistribuidosController);

        ConsultaStatusBilhetesDistribuidosController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', '$cookies',
        'Concurso', 'Bilhete', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function ConsultaStatusBilhetesDistribuidosController ($timeout, $scope, $stateParams,  $http, $state, $cookies,
       Concurso, Bilhete, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.concursos = [];
        vm.statusbilhetes = [];
        vm.filtro = pagingParams.filtro;
        vm.changeconcurso = changeconcurso;
        vm.quantidadeValidados = 0;
        vm.totalItems = 0;        
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.loadAll = loadAll;

        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true; 

        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0) {
                if( vm.filtro.concurso == null){
                    selecionaConcursoPadrao();
                    changeconcurso();
                } else {
                    loadAll();
                }
            }
        });

        function changeconcurso(){
            salvaConncursoCookie();
            vm.filtro.numinicio = null;
            vm.filtro.numfim = null;     
            loadAll();       
        }

        function loadAll () {
            if (vm.filtro.concurso == null || 
                (vm.filtro.lote == null && (vm.filtro.numinicio == null || vm.filtro.numfim == null))){
                vm.statusbilhetes = [];
                vm.quantidadeValidados = 0;
            } else {
                if (vm.filtro.lote == null) {                    
                    Bilhete.consultar_bilhetes_dist({
                        concursoid: vm.filtro.concurso.id,
                        numinicio:vm.filtro.numinicio,
                        numfim:vm.filtro.numfim,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    vm.filtro.numinicio = null;
                    vm.filtro.numfim = null;
                    Bilhete.consultar_bilhetes_lote({
                        concursoid: vm.filtro.concurso.id,
                        lotevalidacao:vm.filtro.lote,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }                        
            }  

           function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.quantidadeValidados = headers('qtd_validados');
                 vm.queryCount = vm.totalItems;     
                 vm.statusbilhetes = [];
                for (var i = 0; i < data.length; i++) {
                    vm.statusbilhetes.push(data[i]);
                }
            }

            function onError(error) {
                console.log(error.data.message)
                AlertService.error("Erro na consulta de bilhetes");
            }
        }

        function reset () {
            vm.page = 0;
            vm.filtro = {'concursoid' : null, 'numinicio' : null, 'numfim' : null};
            vm.quantidadeValidados = 0;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }          
    }
})();
