(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('regional', {
            parent: 'entity',
            url: '/regional?page&sort',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL','PERM_CONSULTAR_REGIONAL'],
                pageTitle: 'sorteioApp.regional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/regional/regionais.html',
                    controller: 'RegionalController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {nome: null, responsavel: null}
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('regional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('regional-detail', {
            parent: 'entity',
            url: '/regional/{id}',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL','PERM_CONSULTAR_REGIONAL'],
                pageTitle: 'sorteioApp.regional.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/regional/regional-detail.html',
                    controller: 'RegionalDetailController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {concurso: null }
            },
            resolve: {
                pagingParams: ['$state', '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    //if ($state.current.name === 'regional-detail') 
                     return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                    //else return {page: 1, sort: 'id,asc'}
                }],   
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('regional');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Regional', function($stateParams, Regional) {
                    return Regional.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'regional',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('regional-detail.edit', {
            parent: 'regional-detail',
            url: '/detail/edit',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-dialog.html',
                    controller: 'RegionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Regional', function(Regional) {
                            return Regional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('regional.new', {
            parent: 'regional',
            url: '/new',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-dialog.html',
                    controller: 'RegionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                responsavel: null,
                                cpf: null,
                                email: null,
                                telefone: null,
                                logradouro: null,
                                numero: null,
                                bairro: null,
                                cidade: null,
                                uf:null,
                                cep: null, 
                                percentualRepasse: null,                               
                                id: null,
                                login: null,
                                senha: null,
                                ativo: true
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('regional', null, { reload: 'regional' });
                }, function() {
                    $state.go('regional');
                });
            }]
        })
        .state('regional.edit', {
            parent: 'regional',
            url: '/{id}/edit',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-dialog.html',
                    controller: 'RegionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Regional', function(Regional) {
                            return Regional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('regional', null, { reload: 'regional' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('regional.delete', {
            parent: 'regional',
            url: '/{id}/delete',
            data: {
                authorities: ['PERM_CADASTRO_REGIONAL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-delete-dialog.html',
                    controller: 'RegionalDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Regional', function(Regional) {
                            return Regional.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('regional', null, { reload: 'regional',  notify: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('regional.distribuicao', {
            parent: 'regional',
            url: '/{id}/{nome}/distribuicao',
            data: {
                authorities: ['PERM_DISTRIBUICAO_REGIONAL']
            },  
            params: { id: null, nome: null },   
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-distribuicao.html',
                    controller: 'DistribuicaoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  function () {
                            return {
                                regionalNome: $stateParams.nome, 
                                regionalId: $stateParams.id
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('regional', null, { reload: 'regional',  notify: false } );
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('regional.devolucao', {
            parent: 'regional',
            url: '/{id}/devolucao',
            data: {
                authorities: ['PERM_DEVOLUCAO_BILHETES']
            },params: {
                nome: ""
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/regional/regional-devolucao.html',
                    controller: 'DevolucaoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        
                        entity:  function () {
                            return {
                                id: $stateParams.id, nome: $stateParams.nome
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('regional', null, { reload: 'regional',  notify: false } );
                }, function() {
                    $state.go('^');
                });
            }]
        })       
        ;
    }

})();
