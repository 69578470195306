(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('SorteioMensalController', SorteioMensalController);

    SorteioMensalController.$inject = ['$scope', '$state', 'SorteioLocalDB', '$stateParams', 'entity', 'AlertService'];

    function SorteioMensalController($scope, $state, SorteioLocalDB, $stateParams, entity, AlertService) {
        var vm = this;
        vm.novonumero = 0;
        vm.concurso = entity;
        vm.numerosSorteados = [];
        vm.bilhetesSorteados = [];
        vm.quantidadeBilhetesPorPontuacao = [];
        var bilhetes = [];
        var numerosSorteio = []
        var indexFaixasPontuacao = [];

        init();
        function init (){
        /*    SorteioLocalDB.db.allDocs({include_docs: false, startkey: 'bilhete/'+vm.concurso.id, endkey: 'bilhete/'+vm.concurso+'\uffff'})
                         .then( function(res){
                                bilhetes = res.rows;
                         });
                         */
            SorteioLocalDB.db.allDocs({include_docs: true, limit:1, startkey: 'concurso/'+vm.concurso.id, endkey: 'concurso/'+vm.concurso+'\uffff'})
                         .then( function(res){
                                vm.concurso = res.rows[0].doc;
                                console.log("Concurso %o",res.rows)
                                for(var a =0; a <= vm.concurso.numerosBilhete; a++){
                                    indexFaixasPontuacao.push(-1);
                                }
                                for(var a =0; a <= vm.concurso.tipo.quantidadeNumeros; a++){
                                    numerosSorteio.push(0);
                                }
                                SorteioLocalDB.dbBil.allDocs({include_docs: false, startkey: 'bilhete/'+vm.concurso.id, endkey: 'bilhete/'+vm.concurso.id+'\uffff'})
                                   .then(function(res){
                                        bilhetes = res.rows;
                                        indexFaixasPontuacao[0] = bilhetes.length-1;
                                        console.log('Foram coletados %d bilhetes ', bilhetes.length);
                                   });
                                /*
                                bilhetes = [{key: 'bilhete/1/1:0C00104203200820'}];
                                        indexFaixasPontuacao[0] = bilhetes.length-1;
                                        */
                         });
        }

        function checkPosCharHex(x, hex){
            var n = (hex < 'A')? hex: (hex.charCodeAt() - 55);
            return (0x01 << (x)) & n;
        };
        function checkPosStringHex(x, str, start){
            var pos = str.length-1;
            while (x > 4){
                x = x-4;
                pos--;
            }
            if (pos >= start){
               return checkPosCharHex(x-1, str.charAt(pos)) != 0;
            }
            return false;
        };
        function testBit(numero, key){
            //console.log(numero +' '+key);
            var start = key.indexOf(':');
            return checkPosStringHex(numero, key, start);
        };
        function calcQuantidadeBilhetesPorPontuacao(){
            vm.quantidadeBilhetesPorPontuacao = [];
            var last = -1;
            for(var ti=indexFaixasPontuacao.length-1; ti >=0; --ti ){
                vm.quantidadeBilhetesPorPontuacao[ti] = indexFaixasPontuacao[ti] - last;
                last = indexFaixasPontuacao[ti];
            }
        }

        function calcGanhadores() {
            vm.bilhetesSorteados = [];
            for (var a=indexFaixasPontuacao[indexFaixasPontuacao.length-1];
                         a> -1; a--){
            SorteioLocalDB.dbBil.get(bilhetes[a].key)
                  .then(function(doc){
                      doc.dezenas = [];
                      if (doc.estabelecimentoId && doc.estabelecimentoId > 0){
                        SorteioLocalDB.db.get('estabelecimento/'+doc.estabelecimentoId)
                            .then( function(est){
                                doc.estabelecimento = est;

                                $scope.$apply();
                            });
                       }
                        for(var b=1; b<= vm.concurso.tipo.quantidadeNumeros; b++){
                            if(testBit(b,doc._id)){
                            doc.dezenas.push(b);
                            }
                        }
                        vm.bilhetesSorteados.push(doc);
                        $scope.$apply();
                }).catch(function(err) {
                    console.log('"Erro bilhete nao encontrador  - %o', err);
                });
            }
        }

        vm.addNumeroSorteado = function (){
            var numero = vm.novonumero;
            if(numero >= numerosSorteio.length || numerosSorteio[numero]==1){
                return false; // numero inválido ou já sorteado
            } else if (indexFaixasPontuacao[indexFaixasPontuacao.length-1]>=0){
                return true;
            }
            numerosSorteio[numero]=true;
            vm.numerosSorteados.push(numero);
            console.log(indexFaixasPontuacao);
            for(var ti=indexFaixasPontuacao.length-1; ti >0; --ti ){
                console.log(ti+' '+indexFaixasPontuacao[ti]+' '+indexFaixasPontuacao[ti-1]);
                var inicio = indexFaixasPontuacao[ti];
                var fim = indexFaixasPontuacao[ti-1];
                for (var i = inicio+1; i <= fim && i  >=0 && i < bilhetes.length; i++) {
                var bi = bilhetes[i];
                if(testBit(numero, bi.key)){
                    ++indexFaixasPontuacao[ti];
                    bilhetes[i] = bilhetes[indexFaixasPontuacao[ti]];
                    bilhetes[indexFaixasPontuacao[ti]] = bi;
                }
                }
            }

            console.log(indexFaixasPontuacao);
            calcQuantidadeBilhetesPorPontuacao();
            calcGanhadores();
            console.log('er'+vm.quantidadeBilhetesPorPontuacao);
            return indexFaixasPontuacao[indexFaixasPontuacao.length-1]>=0;
        }
    }
})();
