(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .directive('focus', focus);

   function focus () {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;        

       function linkFunc ($scope, elem, attrs) {
           elem.bind('keydown', function(e){
               console.log("KEY PRESS %o", e);
               var code = e.keyCode || e.which;
               if(code == 13) {
                    e.preventDefault();
                    // busca pelo próximo input com a diretiva para exibir o foco
                    var pageElems = document.querySelectorAll('input[focus]');
                    var focusNext = false;
                    var len = pageElems.length;
                    for(var i=0; i< len; i++){
                        var pe = pageElems[i];
                        if (focusNext && pe.style.display !== 'none'){
                            pe.focus();
                            break;
                        } else if (pe === this){
                            focusNext = true;
                        } 
                    }
               }
           });
       }
    }

})();
