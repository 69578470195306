(function() {
    'use strict';


    angular
        .module('TrocoPublicoApp')
        .controller('ConsultaEstabelecimentosController', ConsultaEstabelecimentosController);

    ConsultaEstabelecimentosController.$inject = ['$http', '$timeout', 'AlertService'];

    function ConsultaEstabelecimentosController($http, $timeout, AlertService) {
        var vm = this;
        vm.filtro = {cidade: null, uf:null, bairro: null};
        vm.cidades = [];
        vm.bairros = [];
        vm.estabelecimentos = [];
        vm.changecidade = changecidade;
        vm.changebairro = loadAll;
        vm.enderecoCompleto = enderecoCompleto;

        vm.transition = transition;
        vm.queryCount = 0;
        vm.itemsPerPage = 20;
        vm.totalItems = 0;
        vm.page = 1;

        querycidades();
        loadAll ();
        function querycidades(){
            $http.get('api/estabelecimentos-enderecos').then(function (response) {
                vm.cidades = response.data;
            });
        }
        function changecidade(){
            $http.get('api/estabelecimentos-enderecos/'+vm.filtro.cidade+"/").then(function (response) {
                vm.bairros = response.data;                
            });
            vm.filtro.bairro = null;
            loadAll();
        }

        function loadAll () {
            var url = 'api/estabelecimentos-por-enderecos';
            var urlPaginationParams  = '?page='+(vm.page-1)+'&sort=nome';
            if (vm.filtro.cidade) {
                url = url +'/'+vm.filtro.cidade;
                if (vm.filtro.bairro) {
                    url = url +'/'+vm.filtro.bairro;
                }                
            }    
            $http.get(url+urlPaginationParams)
                .then(function (response) {
                        vm.totalItems = response.headers('X-Total-Count');
                        console.log(vm.totalItems);
                        vm.queryCount = vm.totalItems;                                   
                        vm.estabelecimentos = response.data;                                                    
                }).catch(function(err){
                    vm.estabelecimentos = [];
                    vm.totalItems = 0;
                    vm.queryCount = vm.totalItems;                                   
                });            
        }

     function enderecoCompleto(estabelecimento){
            var endereco = '';
            if (estabelecimento.logradouro){
                endereco += estabelecimento.logradouro + ', ';
                if (estabelecimento.numero){
                    endereco += estabelecimento.numero + ', ';
                }
            }
            endereco += estabelecimento.bairro + ', '+ estabelecimento.cidade;
            return endereco;
        }

        function transition () {
            loadAll();
        }        
    }
})();
