(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('Bilhete', Bilhete);

        Bilhete.$inject = ['$resource'];

    function Bilhete ($resource) {
        var urlResourceValidacaoBilhete  = 'api/validarbilhete/:concurso/:bilhete';      
        var urlResourceConsultarBilhete  = 'api/bilhetes-status-faixa/:concursoid/:numinicio/:numfim';      
        var urlResourceConsultarBilheteDist  = 'api/bilhetes-status-dist/:concursoid/:numinicio/:numfim';
        var urlResourceConsultarBilheteLote  = 'api/bilhetes-status-lote/:concursoid/:lotevalidacao';
        var urlResourceConsultarLoteValidacao  = 'api/lotevalidacao-status/:concursoid/:regionalid';      

        return {
            validar:  $resource(urlResourceValidacaoBilhete, {}, {
                'validar': {method: 'GET'} }).validar,
            consultar_todos:  $resource(urlResourceConsultarBilhete, {}, {
                'consultar_todos': {method: 'GET', isArray: true} }).consultar_todos,
            consultar_bilhetes_dist:  $resource(urlResourceConsultarBilheteDist, {}, {
                'consultar_bilhetes_dist': {method: 'GET', isArray: true} }).consultar_bilhetes_dist,     
            consultar_bilhetes_lote:  $resource(urlResourceConsultarBilheteLote, {}, {
                'consultar_bilhetes_lote': {method: 'GET', isArray: true} }).consultar_bilhetes_lote,
            consultar_lotevalidacao:  $resource(urlResourceConsultarLoteValidacao, {}, {
                'consultar_lotevalidacao': {method: 'GET', isArray: true} }).consultar_lotevalidacao                                                                 
        }

    }
})();
