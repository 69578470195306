(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$http'];

    function ProfileService($http) {

        var dataPromise;

        var service = {
            getProfileInfo : getProfileInfo
        };

        return service;

        function getProfileInfo() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = $http.get('api/profile-info').then(function(result) {
                    if (result.data.activeProfiles) {
                        var response = {};
                        response.activeProfiles = result.data.activeProfiles;
                        response.ribbonEnv = result.data.ribbonEnv;
                        response.inProduction = result.data.activeProfiles.indexOf("dev") == -1;
                        response.swaggerEnabled = result.data.activeProfiles.indexOf("swagger") !== -1;
                        
                        if (result.data.activeProfiles.indexOf("cprod") !== -1){
                            response.appname = 'DOAÇÃO DÁ $ORTE';
                            response.applogo = 'logo_apae_sma.jpg';
                            response.applargelogo = 'imagem_doacao_sobral_med.png';    
                            response.Bootswatchtheme = 'Flatly';                        
                        } else if (result.data.activeProfiles.indexOf("prod") !== -1){
                            response.appname = 'SOLIDARIEDADE DÁ $ORTE';
                            response.applogo = 'logo-slb_sma.png';
                            response.applargelogo = 'imagem_solidariedade_med.png';
                            response.Bootswatchtheme = 'Yeti';                            
                        } else if (result.data.activeProfiles.indexOf("aprod") !== -1){
                            response.appname = 'SOLIDARIEDADE DÁ $ORTE';
                            response.applogo = 'logo-slb_sma.png';
                            response.applargelogo = 'imagem_solidariedade_med.png';
                            response.Bootswatchtheme = 'Cerulean';                            
                        } else if (result.data.activeProfiles.indexOf("rasp") !== -1){
                            response.appname = 'DOAÇÃO DÁ $ORTE';
                            response.applogo = 'logo-slb_sma.png';
                            response.applargelogo = 'imagem_doacao_med.png';
                            response.Bootswatchtheme = 'Flatly';                            
                        } else if (result.data.activeProfiles.indexOf("nrasp") !== -1){
                            response.appname = 'RASPADINHADO DO NORTE';
                            response.applogo = 'logo-sorteio_sma.jpg';
                            response.applargelogo = 'bolas_sorteio_med.jpg';
                            response.Bootswatchtheme = 'Default';                                 
                        } else {
                            response.appname = 'APP DESENVOLVIMENTO';
                            response.applogo = 'logo-sorteio_sma.jpg';
                            response.applargelogo = 'bolas_sorteio_med.jpg';
                            response.Bootswatchtheme = 'Default';                            
                        }  

                        return response;
                    }
                });
            }
            return dataPromise;
        }
    }
})();
