(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lote-regional', {
            parent: 'bilhete',
            url: '/lote-regional?page&sort',
            data: {
                authorities: ['PERM_CONFECCAO_LOTES'],
                pageTitle: 'sorteioApp.lote-regional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lote-regional/lotes-regional.html',
                    controller: 'LoteRegionalController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {concurso: null, id: null, regional : null }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('lote-regional.new', {
            parent: 'lote-regional',
            url: '/new',
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            params : {'idconcurso': null, 'idregional': null},
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-regional/lote-regional-dialog.html',
                    controller: 'LoteRegionalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        idconcurso: $stateParams.idconcurso,
                        idregional: $stateParams.idregional
                    }    
                }).result.then(function(res) {
                    $state.go('lote-regional.new', {entity:res} , { reload: 'lote-regional.new' });
                }, function() {
                    $state.go('lote-regional');
                });
            }]
        })   
        .state('lote-regional.confirm', {
            parent: 'lote-regional.new',
            url: '/confirm',
             params : {'entity': null},
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-regional/lote-regional-confirm-dialog.html',
                    controller: 'LoteRegionalConfirmacaoController',
                    controllerAs: 'vm',
                    size: 'md'
                }).result.then(function() {
                    $state.go('lote-regional', null, { reload: 'lote-regional' });
                }, function() {
                    $state.go('^');
                });
            }]
        })             
        .state('lote-regional.delete', {
            parent: 'lote-regional',
            url: '/delete',
            params : {'concurso': null, 'idlote': null},
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-regional/lote-regional-delete-dialog.html',
                    controller: 'LoteRegionalDeleteController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        concurso: $stateParams.concurso,
                        idlote: $stateParams.idlote
                    }                    
                }).result.then(function() {
                    $state.go('lote-regional', null, { reload: 'lote-regional' });
                }, function() {
                    $state.go('^', null, { reload: 'lote-regional' });
                });
            }]
        })
        .state('lote-regional.changeowner', {
            parent: 'lote-regional',
            url: '/edit',
            params : {'concurso': null, 'idlote': null, 'idregional': null},
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-regional/lote-regional-dialog.changeowner.html',
                    controller: 'LoteRegionalChangeOwnerController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        concurso: $stateParams.concurso,
                        idlote: $stateParams.idlote,
                        idregional: $stateParams.idregional                    
                    }                    
                }).result.then(function() {
                    $state.go('lote-regional', {}, { reload: 'lote-regional', notify: false });                    
                }, function() {
                    $state.go('^');
                });
            }]
        })        
        ;        
    }

})();
