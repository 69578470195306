(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('GrupoLancamentoController', GrupoLancamentoController);

    GrupoLancamentoController.$inject = ['$state', 'GrupoLancamento', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function GrupoLancamentoController($state, GrupoLancamento, ParseLinks, AlertService,  pagingParams, paginationConstants) {

        var vm = this;

        vm.gruposlancamento = [];
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.transitonTo = transitonTo;        
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = { last: 0 };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;

        loadAll();

        function loadAll () {
            vm.gruposlancamento = [];
            GrupoLancamento.query({
                page: vm.page-1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'nomeGrupo') {
                    result.push('nomeGrupo');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.gruposlancamento.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.gruposlancamento = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }        

        function transitonTo(statename, stateparams){
            $state.params.page = vm.page;
            $state.params.id = stateparams.id;
            $state.params.nomeGrupo = stateparams.nomeGrupo;
            $state.params.tipoOperacao = stateparams.tipoOperacao;
            $state.params.tipoOperacaoId = stateparams.tipoOperacaoId;                        
            $state.params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            $state.transitionTo(statename, stateparams);
        }                      
    }
})();
