(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('GrupoLancamento', GrupoLancamento);

    GrupoLancamento.$inject = ['$resource', 'DateUtils'];

    function GrupoLancamento ($resource, DateUtils) {
        var resourceUrl =  'api/grupo-lancamento/:id';
        var resourceUrlPorTipo =  'api/grupo-lancamento/tipo/:idtipo';
        var resourceUrlTipo  = 'api/tipo-operacaoFinanceira';

        var resPrincipal = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            },
            'save': {
                method: 'POST'
            }
        });
        var resTipo = $resource(resourceUrlTipo, {}, {
            'query': { method: 'GET', isArray: true}
        });
        var resPorTipo = $resource(resourceUrlPorTipo, {}, {
            'query': { method: 'GET', isArray: true}
        });

      
        return {
            query  : resPrincipal.query,
            get    : resPrincipal.get,
            update : resPrincipal.update,
            save   : resPrincipal.save,
            queryTipos : resTipo.query,
            queryPorTipo : resPorTipo.query
        };
    }
})();
