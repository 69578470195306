(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ConcursoDialogController', ConcursoDialogController);

    ConcursoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Concurso'];

    function ConcursoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Concurso) {
        var vm = this;

        vm.concurso = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.tipos = Concurso.queryTiposBilhetes();
        vm.changeQtdPremios = changeQtdPremios;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function changeQtdPremios(){
            console.log("Entrou!!!");
            if(this.concurso.quantidadePremios && this.concurso.quantidadePremios > 5)
                this.concurso.quantidadePremios = 5;
            else if (this.concurso.quantidadePremios && this.concurso.quantidadePremios < 0)                
                this.concurso.quantidadePremios = 0;
        } 

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.concurso.id) {
                Concurso.update(vm.concurso, onSaveSuccess, onSaveError);
            } else {
                Concurso.save(vm.concurso, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:concursoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataSorteio = false;
        
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
