(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('AreaRegionalController', AreaRegionalController);

    AreaRegionalController.$inject = ['$scope','$state', 'AreaRegional', 'Regional', 'ParseLinks', 'AlertService', 
        'pagingParams', 'paginationConstants'];

    function AreaRegionalController($scope, $state, AreaRegional, Regional, ParseLinks, AlertService, pagingParams, paginationConstants) {

        var vm = this;

        vm.areasregional = [];
        vm.regionais = [];
        vm.filtrar = filtrar;
        vm.limparFiltro = limparFiltro;
        vm.filtro = pagingParams.filtro;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.transitonTo = transitonTo;
        vm.queryCount = 1;
        vm.page = pagingParams.page;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.links = {
            last: 0
        };
        vm.predicate = 'nome';
        vm.reset = reset;
        vm.reverse = true;
        
        if (vm.filtro.ativo === undefined) vm.filtro.ativo  = true;
        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
        });

        loadAll();
        function limparFiltro(){
            vm.filtro = {nome: null, telefone: null, regional : null};
            loadAll();
        }
        function filtrar(){
            vm.page = 1;
            vm.links = { last: 0 };
            load(vm.filtro);
        }
        $scope.$on("areaRegionalUpdate",function(){
            console.log('updating');
            loadAll();
         });

        function loadAll () {
            load(vm.filtro);
        }
        function load(filtro){
            var obj = JSON.parse(JSON.stringify(filtro));            
            obj.page = vm.page - 1;     
            obj.size = vm.itemsPerPage;
            obj.sort = sort();
            if (!obj.ativo) obj.ativo = null;            
            AreaRegional.query( obj, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'nome') {
                    result.push('nome');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;              
                vm.areasregional = [];      
                console.log('onSuccess %o', data);                  
                for (var i = 0; i < data.length; i++) {
                    vm.areasregional.push(data[i]);
                }
            }

            function onError(error) {
                console.log('onError %o', error);   
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 1;
            vm.areasregional = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }   
        function transitonTo(statename, stateparams){
            $state.params.filtro = vm.filtro;
            $state.params.page = vm.page;
            $state.params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            $state.transitionTo(statename, stateparams);
        }              
    }
})();
