(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('DistribuicaoBilhetesController', DistribuicaoBilhetesController);

        DistribuicaoBilhetesController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$cookies',
       'Concurso', 'Regional', 'Estabelecimento', 'AlertService', 'DistribuicaoBilhetes'];

    function DistribuicaoBilhetesController ($timeout, $scope, $stateParams,  $http, $cookies,
        Concurso, Regional, Estabelecimento, AlertService, DistribuicaoBilhetes) {
        var vm = this;

        vm.concursos = [];
        vm.lastValidations = [];
        vm.alerts = [];        
        vm.limpar = limpar;
        vm.distribuir = distribuir;
        vm.buscar = buscar;
        vm.changeconcurso = changeconcurso;
        vm.changeregional = changeregional;
        
        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
          });
        };

        Concurso.querynaosinc().$promise.then(function(res){
            vm.concursos = res;
            if(vm.lote.concurso == null){
                selecionaConcursoPadrao();
                changeconcurso();
            }
        });
        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
            if (vm.regionais.length == 1){
                vm.lote.regional = vm.regionais[0];
            }
        });        

        function changeconcurso(){
            salvaConncursoCookie();
            vm.lote.bilheteInicio = null;
            vm.lote.bilheteFim = null;            
        }

        function changeregional(){
            if (vm.lote.regional){
                Estabelecimento.queryTodosAtivosPorRegional({'regionalId': vm.lote.regional.id}).$promise.then(function(res){
                    vm.estabelecimentos = _.orderBy(res, ['nome'], ['asc']);
                    if (vm.estabelecimentos.length == 1){
                        vm.lote.estabelecimento = vm.estabelecimentos[0];
                    }    
                });        
            } else {
                vm.lote.estabelecimento = null;
            }
        }
       function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };

        function limpar () {
            vm.lote.concurso=null;
            vm.lote.bilheteInicial = null;
            vm.lote.bilheteFinal = null;
            vm.lote.regional = null;
            vm.lote.estabelecimento = null;                                    
        }

        function distribuir () {
            if (vm.lote.concurso.id && vm.lote.bilheteInicial && 
                vm.lote.bilheteFinal && vm.lote.motivoDistribuicao ){
                vm.isSaving = true;
                if (vm.lote.estabelecimento){
                    DistribuicaoBilhetes.distribuiEstabelecimento({id: null,                      
                        estabelecimentoId : vm.lote.estabelecimento.id,
                        concursoId: vm.lote.concurso.id,
                        bilheteInicial : vm.lote.bilheteInicial,
                        bilheteFinal: vm.lote.bilheteFinal,
                        motivoDistribuicao: vm.lote.motivoDistribuicao
                    }).$promise.then( onSaveSuccess).catch(onSaveError);
                } else {
                    DistribuicaoBilhetes.distribuiRegional({id: null,
                        regionalId : (vm.lote.regional)? vm.lote.regional.id : null,
                        concursoId: (vm.lote.concurso)? vm.lote.concurso.id : null,
                        bilheteInicial : vm.lote.bilheteInicial,
                        bilheteFinal : vm.lote.bilheteFinal,
                        motivoDistribuicao: vm.lote.motivoDistribuicao   
                    }, onSaveSuccess, onSaveError);                    
                }
            }
        }
            function onSaveSuccess (result) {
                vm.isSaving = false;
                AlertService.success('Lote de venda criado ');
                buscar();
            }
    
            function onSaveError (response) {
                vm.isSaving = false;
                var msg = 'Houve um erro na criação do lote de venda de bilhetes!';
                if (response.status == 400){
                    var errorkey = response.headers('X-sorteioApp-error');
                    var errorparam = response.headers('X-sorteioApp-params');
                    
                    if (errorkey === 'error.bilheteinvalido'){
                        msg = 'Bilhete inválido: ' + errorparam; 
                    } else if (errorkey === 'error.periodoinvalido'){
                        msg = 'Faixa não disponível! Verifique se existem bilhetes entregues a outra regional.'; 
                    } else if (errorkey === 'error.sempermissao'){
                        msg = 'O usuário não tem permissão para alterar todos os bilhetes deste intervalo.';                    
                    } else if (errorkey === 'error.bilhetejavalidado'){
                        msg = 'Existem bilhetes validados no intervalo informado.';                    
                    }  else if (errorkey === 'error.bilhetenaocorrespondeconcurso'){
                        msg = 'Bilhete ' + errorparam + ' não corresponde ao concurso selecionado.'; 
                    } else if (errorkey === 'error.faixapertenceoutrolote'){
                        msg = 'Parte dos bilhetes já atribuidos a uma regional diferente deste estabelecimento.';
                    } else if (errorkey === 'error.loteOutraRegional'){
                        msg = 'Parte dos bilhetes já atribuidos a uma outra regional.';
                    }
                    
                } 
                AlertService.error(msg);      
            }

        function  buscar (){
            DistribuicaoBilhetes.queryLogDistribuicaoBilhetes(
                {'concursoId': vm.lote.concurso.id,
                 page: 0, size: 50})
            .$promise.then( function(data) {
               vm.logdistribuicao = data;
               console.log(data);
            }).catch(function(){
               vm.logdistribuicao = [];
               AlertService.error("Não foi possível coletar o log de distribuições");
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.lote.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.lote.concurso);
        }          
    }
})();
