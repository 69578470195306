(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('TotalizacaoEstabelecimentosController', TotalizacaoEstabelecimentosController);

        TotalizacaoEstabelecimentosController.$inject = ['$timeout', '$scope', '$http', '$cookies',
        'Concurso', 'Regional','AreaRegional', 'DistribuicaoBilhetes', 'Principal', 'AlertService'];

    function TotalizacaoEstabelecimentosController ($timeout, $scope, $http, $cookies,
        Concurso, Regional, AreaRegional, DistribuicaoBilhetes, Principal, AlertService) {
        var vm = this;
        vm.totalizacao =  {};
        vm.concursos = [];
        vm.tipos = [];
        vm.regionais = [];
        vm.areasregional = [];                
        vm.filtro = {concurso: null, regional: null};
        vm.changeregional = changeregional;
        vm.changearea = changearea;
        vm.changeconcurso = changeconcurso;
        vm.saveCSV = saveCSV;
        vm.requesting = false;
        vm.sumBy = function (a, b){
            return _.sumBy(a,b);
        }
        vm.calcPreco = function (t){
            return t.totalValidados  * vm.totalizacao.concurso.tipo.valor; 
        }
        vm.calcComissao = function(t){
            console.log('caccomissa');
            return t.totalValidados  * vm.totalizacao.concurso.tipo.valor * (t.estabelecimento.percentualRepasse / 100.0);
        }

        Regional.queryTodos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);   
            Concurso.queryshort().$promise.then(function(res){
                vm.concursos = res;
                if(vm.concursos.length > 0){
                    selecionaConcursoPadrao();
                    changeconcurso();
                }
            });
        });

        Concurso.queryTiposBilhetes().$promise.then(function(res){
            vm.tipos = res;
        });



        function changeconcurso(){
            salvaConncursoCookie();
            vm.filtro.regional = null;
            changeregional();
        }

        function changeregional(){
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }            
            vm.filtro.area = null; 
            if (vm.filtro.regional){
                AreaRegional.queryPorRegional({'regionalId': vm.filtro.regional.id }).$promise.then(function(res){
                    vm.areasregional = _.orderBy(res, ['nome'], ['asc']);
                });
            }            
            changearea();
        }
        function changearea(){
            if (!vm.filtro.regional){
                vm.areasregional = [];
            }            
            loadAll();
        }         
        function reset(){
            vm.totalizacao = {};
        }
        function saveCSV(){
            requestData(onSuccessSaveCSVRequest);
        }    
        function loadAll () {
            requestData(onSuccessSearchRequest);
        }

        function requestData (successFunction) {        
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            } 
            if (!vm.filtro.concurso || !vm.filtro.regional || !vm.tipos) {
                reset();
            } else {       
                vm.requesting = true;    
                DistribuicaoBilhetes.queryTotalizacaoEstabelecimentos({
                    concursoId: vm.filtro.concurso.id,
                    regionalId: vm.filtro.regional.id,
                    areaId: (vm.filtro.area)? vm.filtro.area.id : null,
                }, successFunction, onError);

                function onError(error) {
                    vm.requesting = false;
                    AlertService.error(error.data.message);
                };                    
            }
        }
        function onSuccessSearchRequest(data, headers) {   
            vm.requesting = false;     
            vm.totalizacao = data;
        }        
        function onSuccessSaveCSVRequest(data, headers) {
            Principal.hasAuthority('PERM_VISUALIZ_TOTALIZACAO')
            .then(function (result) {
                vm.requesting = false;
                var hasAuthority = result;                
                var lista = data.totaisEstabelecimentos;
                _.sortBy(lista, ['estabelecimento.nome']);
                var content =  'Concurso;COD_Reg;Regional;COD_Est;Estabelecimento;Área;Endereço;Qtd.Distribuição;Qtd.Validados';
                if(hasAuthority) content += ';Valor Total;Percent.;Comissão';
                content += '\n';
                for (var i = 0; i < lista.length; i++) {
                    content += (data.concurso.label).replace(/[;,\n]/g,'')+';';
                    content += data.regional.id+';';
                    content += (data.regional.nome).replace(/[;,\n]/g,'')+';';
                    content += lista[i].estabelecimento.id+';';
                    content += (lista[i].estabelecimento.nome).replace(/[;,\n]/g,'')+';';
                    content += (((lista[i].estabelecimento&&lista[i].estabelecimento.areaRegional)?lista[i].estabelecimento.areaRegional.nome:'')).replace(/[;,\n]/g,'')+';';
                    content += (lista[i].estabelecimento.bairro + '/' + lista[i].estabelecimento.cidade+ '/' + lista[i].estabelecimento.uf).replace(/[;,\n]/g,'')+';';
                    content += lista[i].totalLotesVenda+';';
                    content += lista[i].totalValidados;                  
                    if(hasAuthority){
                        content += ';"'+(''+(lista[i].totalValidados * data.concurso.tipo.valor)).replace(/\./g,',')+'"';
                        content += ';'+lista[i].estabelecimento.percentualRepasse;
                        content += ';"'+(''+(lista[i].totalValidados * data.concurso.tipo.valor * (lista[i].estabelecimento.percentualRepasse/ 100.0))).replace(/\./g,',')+'"';           
                    } 
                    content +='\n';
                }
                content += (data.concurso.label).replace(/[;,\n]/g,'')+';';
                content +=';';
                content += (data.regional.nome).replace(/[;,\n]/g,'')+';';
                content +=';TOTAL;;;';
                content += vm.sumBy(vm.totalizacao.totaisEstabelecimentos, 'totalLotesVenda')+';';
                content += vm.sumBy(vm.totalizacao.totaisEstabelecimentos, 'totalValidados');
                if(hasAuthority){
                    content += ';"'+ (''+vm.sumBy(vm.totalizacao.totaisEstabelecimentos, vm.calcPreco)).replace(/\./g,',')+'"';
                    content += ';';
                    content += ';"'+ (''+vm.sumBy(vm.totalizacao.totaisEstabelecimentos, vm.calcComissao)).replace(/\./g,',')+'"';
                }
                //content +='\n';
                //var hiddenElement = document.createElement('a');
                //hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
                //hiddenElement.download = 'relatorio_totalizacao_estabelecimentos'+vm.filtro.concurso.id+'.csv';
                //document.body.appendChild(hiddenElement);
                //hiddenElement.click();
                var fileName = 'relatorio_totalizacao_estabelecimentos'+vm.filtro.concurso.id+'.csv';
                var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
                var csvContentEncoded = textEncoder.encode([content]);
                var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
                saveAs(blob, fileName);                  
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }     
    }
})();
