(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('GeracaoDialogController', GeracaoDialogController);

    GeracaoDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$uibModalInstance',
                   'entity', 'Concurso', 'AlertService'];

    function GeracaoDialogController ($timeout, $scope, $stateParams,  $http, $uibModalInstance,
            entity, Concurso, AlertService) {
        var vm = this;

        vm.geracao = entity;
        vm.clear = clear;
        vm.save = save;
        vm.geracao.online = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var req = {
               method: 'POST',
               url: 'api/lotebilhete-geracao/'+vm.geracao.concurso.id+
                    '?&quantidade='+vm.geracao.quantidade+
                    '&online='+vm.geracao.online,
               headers: {
                  'Content-Type': 'application/json'
               }
            };

            $http(req).then(onSaveSuccess, onSaveError);
            //Venda.save(vm.venda, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            AlertService.success('Foram criados '+vm.geracao.quantidade+' itens.');
        }

        function onSaveError (error) {
            vm.isSaving = false;
            AlertService.error('Houve um erro na geração dos bilhetes!');
            console.log(error.data.message);
        }

    }
})();
