(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ConsultaStatusLoteValidacaoController', ConsultaStatusLoteValidacaoController);

        ConsultaStatusLoteValidacaoController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', '$cookies',
        'Concurso', 'Regional', 'Bilhete', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function ConsultaStatusLoteValidacaoController ($timeout, $scope, $stateParams,  $http, $state, $cookies,
       Concurso, Regional, Bilhete, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.concursos = [];
        vm.regionais = [];        
        vm.statusbilhetes = [];
        vm.filtro = pagingParams.filtro;
        vm.changeconcurso = changeconcurso;
        vm.changeregional = changeregional;
        vm.quantidadeValidados = 0;
        vm.totalItems = 0;        
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.loadAll = loadAll;

        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true; 

        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);   
            if (vm.regionais.length > 1){
                vm.regionais.unshift({'id': null, 'nome': "Todas Regionais"});
            }             
            Concurso.queryshort().$promise.then(function(res){
                vm.concursos = res;
                if(vm.concursos.length > 0) {
                    if( vm.filtro.concurso == null){
                        selecionaConcursoPadrao();
                        changeconcurso();
                    } else {
                        changeregional();
                    }
                }
            });
        });

        function changeconcurso(){
            salvaConncursoCookie();
            vm.filtro.regionalid = null;
            changeregional();
        }

        function changeregional(){
            if (vm.filtro.regionalid == null && vm.regionais.length > 0){
                vm.filtro.regionalid = vm.regionais[0].id;
            }                                       
            loadAll();       
        }

        function loadAll () {
            if (vm.filtro.concurso == null ){
                vm.statusbilhetes = [];
                vm.quantidadeValidados = 0;
            } else {
                
                Bilhete.consultar_lotevalidacao({
                    concursoid: vm.filtro.concurso.id,
                    regionalid:vm.filtro.regionalid,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
    
            }  

           function sort() {
                //var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                //if (vm.predicate !== 'loteValidacao') {
                //    result.push('loteValidacao');
                //}
                return "loteValidacao,asc";
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.quantidadeValidados = headers('qtd_validados');
                 vm.queryCount = vm.totalItems;     
                 vm.statusbilhetes = [];
                for (var i = 0; i < data.length; i++) {
                    vm.statusbilhetes.push(data[i]);
                }
            }

            function onError(error) {
                console.log(error.data.message)
                AlertService.error("Erro na consulta de bilhetes");
            }
        }

        function reset () {
            vm.page = 0;
            vm.filtro = {'concursoid' : null, 'regionalid' : null};
            vm.quantidadeValidados = 0;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }          
    }
})();
