(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('Regional', Regional);

    Regional.$inject = ['$resource'];

    function Regional ($resource) {
        var urlResource =  'api/regionais/:id';
        var urlResourceRegionaisAtivas = 'api/regionais-ativas'
        var urlResourceRegionaisTodas = 'api/regionais-todas'

        var resourcePrincipal = $resource(urlResource, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });


        function getQueryResurce(url){
            return $resource(url, {}, {
                    'query': {method: 'GET', isArray: true}
                }).query;
        } 

        return {
            query  : resourcePrincipal.query,
            get    : resourcePrincipal.get,
            update : resourcePrincipal.update,
            save   : resourcePrincipal.save,
            delete : resourcePrincipal.delete,
            queryAtivos : getQueryResurce(urlResourceRegionaisAtivas),
            queryTodos : getQueryResurce(urlResourceRegionaisTodas)
        }        
    }
})();
