(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .factory('BootSwatchService', BootSwatchService);
    
    BootSwatchService.$inject = ['$http'];

    function BootSwatchService ($http) {
        return {
            get: function() {
                return $http.get('https://bootswatch.com/api/3.json').then(function (response) {
                   console.log("OK"); 
                   return response.data.themes;
                }).catch(function (error) {
                    console.log("RROR");
                    console.log(error);
                });;
            }
        };
    }
})();
