(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('grupolancamento', {
            parent: 'entity',
            url: '/grupolancamento',
            data: {
                authorities: ['PERM_CADASTRO_GRUPO_LANCAMENTO'],
                pageTitle: 'sorteioApp.grupolancamento.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grupo_lancamento/grupo_lancamento.html',
                    controller: 'GrupoLancamentoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'nome,asc',
                    squash: true
                }
            },            
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('regional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('grupolancamento.edit', {
            parent: 'grupolancamento',
            url: '/detail/edit',
            data: {
                authorities: ['PERM_CADASTRO_GRUPO_LANCAMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grupo_lancamento/grupo_lancamento-dialog.html',
                    controller: 'GrupoLancamentoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: {id: $state.params.id, nomeGrupo: $state.params.nomeGrupo, tipoOperacaoId: $state.params.tipoOperacaoId}
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('grupolancamento.delete', {
            parent: 'grupolancamento',
            url: '/detail/delete',
            data: {
                authorities: ['PERM_CADASTRO_GRUPO_LANCAMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grupo_lancamento/grupo_lancamento-delete-dialog.html',
                    controller: 'GrupoLancamentoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: {id : $state.params.id}
                    }
                }).result.then(function() {
                    $state.go('grupolancamento', null, { reload: 'grupolancamento' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('grupolancamento.new', {
            parent: 'grupolancamento',
            url: '/new',
            data: {
                authorities: ['PERM_CADASTRO_GRUPO_LANCAMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grupo_lancamento/grupo_lancamento-dialog.html',
                    controller: 'GrupoLancamentoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('grupolancamento', null, { reload: 'grupolancamento' });
                }, function() {
                    $state.go('grupolancamento');
                });
            }]
        })
        ;
    }

})();
