(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RegionalController', RegionalController);

    RegionalController.$inject = ['$scope','$state', 'Principal', 'Regional', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function RegionalController($scope, $state, Principal, Regional, ParseLinks, AlertService, pagingParams, paginationConstants) {

        var vm = this;

        vm.regionais = [];
        vm.filtrar = filtrar;
        vm.filtro = pagingParams.filtro;
        vm.limparFiltro = limparFiltro;
        vm.saveCSV = saveCSV;        
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.transitonTo = transitonTo;
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'nome';
        vm.reset = reset;
        vm.reverse = true;

        if (vm.filtro.ativo === undefined) vm.filtro.ativo  = true;
        loadAll();
        function limparFiltro(){
            vm.page = 1;
            vm.links = { last: 0 };            
            vm.filtro = {nome: null, responsavel: null};
            loadAll();
        }
        $scope.$on("updateListRegional",function(){
            console.log('updating');
            loadAll();
         });

        function filtrar(){
            vm.page = 1;
            vm.regionais = [];        
             load(vm.filtro, onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
        }
        function loadAll () {
            load(vm.filtro, onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
        }

        function saveCSV(){
            load(vm.filtro, onSuccessSaveCSVRequest, 0, 10000000);
        }    

        function load(filtro, successFunction, startPage, pageSize){
            var obj = JSON.parse(JSON.stringify(filtro));
            obj.page = startPage;     
            obj.size = pageSize;
            obj.sort = sort();            
            if (!obj.ativo) obj.ativo = null;
            Regional.query(obj, successFunction, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'nome') {
                    result.push('nome');
                }
                return result;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onSuccessSearchRequest(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.regionais = [];
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;                
            for (var i = 0; i < data.length; i++) {
                vm.regionais.push(data[i]);
            }
        }
        function onSuccessSaveCSVRequest(data, headers) {             
            var lista = data;
            _.sortBy(lista, ['nome']);
            var content = 'ID;Nome;Responsável;Comissão;Situação\n';
            for (var i = 0; i < lista.length; i++) {
                content += lista[i].id+';';
                content += (lista[i].nome).replace(/[,;]/g,'')+';';
                content += (lista[i].responsavel).replace(/[,;]/g,'')+';';
                content += lista[i].percentualRepasse+';';
                content += (lista[i].ativo?'Ativo':'Inativo')+'\n';
            }
            //var hiddenElement = document.createElement('a');
            ///hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
            //hiddenElement.download = 'lista_regionais.csv';
            //document.body.appendChild(hiddenElement);
            //hiddenElement.click();
            var fileName = 'lista_regionais.csv';
            var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
            var csvContentEncoded = textEncoder.encode([content]);
            var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
            saveAs(blob, fileName);              
        }                
        function reset () {
            vm.page = 1;
            vm.regionais = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }
        function transitonTo(statename, stateparams){
            $state.params.filtro = vm.filtro;
            $state.params.page = vm.page;
            $state.params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            $state.transitionTo(statename, stateparams);
        }         
    }
})();
