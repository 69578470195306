(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LancamentoController', LancamentoController);

    LancamentoController.$inject = ['$cookies', '$state', 'Lancamento', 'GrupoLancamento', 'Concurso', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'DateUtils'];

    function LancamentoController($cookies, $state, Lancamento, GrupoLancamento, Concurso, ParseLinks, AlertService, pagingParams, paginationConstants, DateUtils) {

        var vm = this;


        vm.filtro = pagingParams.filtro;    
        vm.lancamentos = [];
        vm.tiposOperacao = [];        
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.filtrar = filtrar;
        vm.saveCSV = saveCSV;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.transitonTo = transitonTo;        
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = { last: 0 };
        vm.predicate = 'dataLancamento';
        vm.reset = reset;
        vm.reverse = true;

        GrupoLancamento.queryTipos().$promise.then(function(res){
            vm.tiposOperacao = _.orderBy(res, ['nome'], ['asc']);
        });

        loadAll();
        
        function filtrar(){
            vm.page = 1;
            vm.links = { last: 0 };
            load(vm.filtro, onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
         }
        function loadAll () {         
            load(vm.filtro, onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
        }

        function saveCSV(){
            load(vm.filtro, onSuccessSaveCSVRequest, 0, 10000000);
        }    

        function load(obj, successFunction, startPage, pageSize){
            if (vm.filtro.dataInicio && vm.filtro.dataFim){
                vm.lancamentos = [];
                var obj = { dataLancamento: [vm.filtro.dataInicio.toJSON().slice(0,10), vm.filtro.dataFim.toJSON().slice(0,10)] };
                if(vm.filtro.tipoOperacao){
                    obj['grupoLancamento.tipoOperacao.id'] = vm.filtro.tipoOperacao.id;
                }
                obj.page = startPage;     
                obj.size = pageSize;
                obj.sort = sort();
                Lancamento.query(obj, successFunction, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'dataLancamento') {
                    result.push('dataLancamento');
                }
                return result;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onSuccessSearchRequest(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;                     
            for (var i = 0; i < data.length; i++) {
                data[i].dataLancamento = DateUtils.convertDateTimeFromServer(data[i].dataLancamento);                
                vm.lancamentos.push(data[i]);
            }
            _.sortBy(vm.lancamentos, ['dataLancamento','grupoLancamento.tipoOperacao.nome','grupoLancamento.nomeGrupo','descricao']);            
        }

        function onSuccessSaveCSVRequest(data, headers) {             
            var lista = data;
            _.sortBy(lista, ['dataLancamento','grupoLancamento.tipoOperacao.nome','grupoLancamento.nomeGrupo','descricao']);
            var content = 'Data;Tipo Operação;Grupo;Descrição;Valor\n';
            for (var i = 0; i < lista.length; i++) {
                lista[i].dataLancamento = DateUtils.convertDateTimeFromServer(lista[i].dataLancamento);                
                content += lista[i].dataLancamento.toLocaleString().slice(0,10)+';';
                content += (lista[i].grupoLancamento.tipoOperacao.nome).replace(/[,;]/g,'')+';';
                content += (lista[i].grupoLancamento.nomeGrupo).replace(/[,;]/g,'')+';';
                content += (lista[i].descricao).replace(/[,;]/g,'')+';';
                content += '"'+(''+lista[i].valor).replace(/\./g,',') +'"\n';
            }
            var fileName = 'lista_lancamentos.csv';
            var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
            var csvContentEncoded = textEncoder.encode([content]);
            var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
            saveAs(blob, fileName);              
        }                

        function reset () {
            vm.page = 0;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        vm.datePickerOpenStatus.dataInicio = false;
        vm.datePickerOpenStatus.dataFim = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
          
        function reset () {
            vm.page = 0;
            vm.lancamentos = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }        
        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }
        function transitonTo(statename, stateparams){
            $state.params.page = vm.page;
            $state.params.id = stateparams.id;
            $state.params.descricao = stateparams.descricao;  
            $state.params.dataLancamento =  stateparams.dataLancamento;
            $state.params.grupoLancamento = stateparams.grupoLancamento;
            $state.params.valor = stateparams.valor;          
            $state.params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            $state.transitionTo(statename, stateparams);
        }                      
    }
})();
