(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('DevolucaoDialogController', DevolucaoDialogController);

    DevolucaoDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$uibModalInstance', 
       'entity', 'Concurso', 'AlertService', 'LoteDevolucao'];

    function DevolucaoDialogController ($timeout, $scope, $stateParams,  $http, $uibModalInstance, 
       entity, Concurso, AlertService, LoteDevolucao) {
        var vm = this;

        vm.concursos = [];
        vm.regional = entity;
        console.log("ENTITY %o",entity);
        vm.formlote = {lista : [], concurso: null }
        vm.packdevolucao = {lista: [] };
        vm.alerts = [];        
        vm.clear = clear;
        vm.save = save;
        vm.addLote = addLote;        
        vm.changeconcurs = changeconcurso;
        vm.allVerificationsOK = allVerificationsOK;
        vm.removeFaixa = removeFaixa;
        vm.mouseOverVerificationStatus = mouseOverVerificationStatus;
        vm.mouseLeaveVerificationStatus = mouseLeaveVerificationStatus;
        vm.verificationStatusTooltip = "";

        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
          });
        };
        positionFirstInput();

        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0){
                vm.formlote.concurso=vm.concursos[0];
            }
        });

        function changeconcurso(){
            vm.formlote = {lista : [], concurso: null}
        }

        function addLote(){

            if (vm.formlote.loteid) {
                for(var a=0; a < vm.packdevolucao.lista.length; a++){
                    if (vm.formlote.loteid === vm.packdevolucao.lista[a].loteid){
                       return;
                    }
                }                     
                LoteDevolucao.verificaDevolucaoLoteBilhetes({regionalId: vm.regional.id, concursoId: vm.formlote.concurso.id,
                    loteId: vm.formlote.loteid}).$promise
                              .then(function (response){ 
                                  for(var a=0; a < response.length; a++){
                                    var obj = {verification: true, msg: 'Lote pronto para devolução!'}; 
                                    obj.bilheteInicial = response[a].bilheteInicial.replace('-','');
                                    obj.bilheteFinal = response[a].bilheteFinal.replace('-','');
                                    obj.loteid = response[a].loteRegionalId;
                                    obj.regional = response[a].regional.nome;
                                    obj.tipo = response[a].tipo;
                                    if(response[a].estabelecimento)
                                        obj.estabelecimento = response[a].estabelecimento.nome;
                                    obj.trackprop = obj.loteid +":"+ obj.bilheteInicial;
                                    vm.packdevolucao.lista.push(obj);
                                    vm.formlote.loteid = null;
                                  }
                              }).catch(function(response){
                                    var obj = {verification: false, loteid: vm.formlote.loteid};
                                    if (response.status == 400){
                                        var errorkey = response.headers('X-sorteioApp-error');
                                        var errorparam = response.headers('X-sorteioApp-params');
                                        
                                        if (errorkey === 'error.loteInexistenteNoConcurso'){
                                            obj.msg = 'Lote inexistente: ' + errorparam; 
                                        } else if (errorkey === 'error.loteOutraRegional'){
                                            obj.msg = 'Bilhetes associados a ourta regional: ' + errorparam;  
                                        } else if (errorkey === 'error.bilhetesJaDevolvidos'){
                                            obj.msg = 'Os bilhetes já foram devolvidos anteriormente.';                                            
                                        } else if (errorkey === 'error.loteNaoVendidoIntegralmente'){
                                            obj.msg = 'O Lote não foi integralmente vendido.';  
                                        } else if (errorkey === 'error.devolucaoinvalida'){
                                            obj.msg = 'Devolução inválida: '+errorparam;  
                                        } else{
                                            obj.msg = 'Problema na verificação da lote';   
                                        }
                                    } else {
                                        obj.msg = 'Problema na verificação da lote';   
                                    }
                                    obj.trackprop = obj.loteid +":";
                                    vm.packdevolucao.lista.push(obj);
                              });
                    positionFirstInput();   
            } else if (vm.formlote.bilheteInicial && vm.formlote.bilheteFinal && vm.formlote.bilheteInicial <= vm.formlote.bilheteFinal){
                var obj = {bilheteInicial: vm.formlote.bilheteInicial, bilheteFinal: vm.formlote.bilheteFinal};
                obj.bilheteInicial = obj.bilheteInicial.replace('-','');
                obj.bilheteFinal = obj.bilheteFinal.replace('-','');

                for(var a=0; a < vm.packdevolucao.lista.length; a++){
                    if (obj.bilheteInicial === vm.packdevolucao.lista[a].bilheteInicial){
                       return;
                    }
                }                

                LoteDevolucao.verificaDevolucaoFaixaBilhetes({regionalId: vm.regional.id, concursoId: vm.formlote.concurso.id,
                                        identificacaoInicial: obj.bilheteInicial,  identificacaoFinal: obj.bilheteFinal}).$promise
                              .then(function (response){ 
                                  obj.verification = true;
                                  obj.msg = 'Faixa pronta para devolução!'; 
                                  obj.loteid = response.loteRegionalId;
                                  obj.regional = response.regional.nome;
                                  obj.tipo = response.tipo;
                                  if(response.estabelecimento)
                                      obj.estabelecimento = response.estabelecimento.nome;
                                  obj.trackprop = obj.loteid +":"+ obj.bilheteInicial;
                                  vm.packdevolucao.lista.push(obj);
                              }).catch(function(response){
                                  obj.verification = false;
                                    if (response.status == 400){
                                        var errorkey = response.headers('X-sorteioApp-error');
                                        var errorparam = response.headers('X-sorteioApp-params');
                                        
                                        if (errorkey === 'error.bilheteInexistente'){
                                            obj.msg = 'Bilhete inválido ou inexistente: ' + errorparam; 
                                        } else if (errorkey === 'error.loteOutraRegional'){
                                            obj.msg = 'Bilhetes associados a ourta regional: ' + errorparam;  
                                        } else if (errorkey === 'error.faixaNenhumLote'){
                                            obj.msg = 'Os bilhetes não estão associados a nenhum lote.';  
                                        } else if (errorkey === 'error.bilhetesJaDevolvidos'){
                                            obj.msg = 'Os bilhetes já foram devolvidos anteriormente.';
                                        } else{
                                            obj.msg = 'Problema na verificação da faixa';   
                                        }
                                    } else {
                                        obj.msg = 'Problema na verificação da faixa';   
                                    }
                                    obj.trackprop = ":"+ obj.bilheteInicial;
                                  vm.packdevolucao.lista.push(obj);
                              });
                positionFirstInput();                
            }
        }

        function mouseOverVerificationStatus(trackprop){
            for(var a=0; a < vm.packdevolucao.lista.length; a++){
                if (trackprop === vm.packdevolucao.lista[a].trackprop){
                   vm.verificationStatusTooltip = vm.packdevolucao.lista[a].msg;
                   break;
                }
            }
        }

        
        function removeFaixa(trackprop){
            for(var a=0; a < vm.packdevolucao.lista.length; a++){
                if (trackprop === vm.packdevolucao.lista[a].trackprop){
                   vm.packdevolucao.lista.splice(a, 1);
                   break;
                }
            }
        }

        function mouseLeaveVerificationStatus() {
              vm.verificationStatusTooltip = "";
        }

        function allVerificationsOK(){
            var ok = true;
            for(var a=0; ok && a < vm.packdevolucao.lista.length; a++){
                ok = ok && vm.packdevolucao.lista[a].verification;
            }
            return ok;
        }
       function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            LoteDevolucao.devolveBilhetes({concursoId: vm.formlote.concurso.id,                      
                regionalId : vm.regional.id,
                lotes: vm.packdevolucao.lista
            }).$promise.then( onSaveSuccess).catch(onSaveError);
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            AlertService.success('Lote de devolução criado ');
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if (error && error.status === 400){
                AlertService.error('Faixa de bilhetes inválida ou não disponível.');    
            } else {
                AlertService.error('Houve um erro na devolução de bilhetes!');
            }           
        }

    }
})();
