(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('DistribuicaoDialogController', DistribuicaoDialogController);

    DistribuicaoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$http', '$cookies', '$uibModalInstance', 
        'entity', 'Concurso', 'DistribuicaoBilhetes', 'AlertService'];

    function DistribuicaoDialogController ($timeout, $scope, $stateParams, $http, $cookies, $uibModalInstance,
       entity, Concurso, DistribuicaoBilhetes, AlertService) {
        var vm = this;

        vm.lotedistribuicao = entity;
        vm.lotedistribuicao.regionalNome = $stateParams.nome;
        vm.lotedistribuicao.lista = [];
        
        vm.concursos = [];
        vm.lotesid = [];
        vm.lotes = [];
        vm.alerts = [];
        vm.clear = clear;
        vm.save = save;
        vm.changeconcurso = changeconcurso;
        vm.reset = reset; 

        function reset(){
           vm.lotedistribuicao.lista = [];            
           vm.alerts = [];
           vm.lotedistribuicao.loteid = null;
        }

        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
          });
        };
        positionFirstInput();
        Concurso.querynaosinc().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0){
                selecionaConcursoPadrao();
                changeconcurso();
            }
        });

        function changeconcurso(){
            salvaConncursoCookie();                 
            vm.lotedistribuicao.bilheteInicial = null;
            vm.lotedistribuicao.bilheteFinal = null;

        } 


        function clear () {
            reset();
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            DistribuicaoBilhetes.distribuiRegional({id: null,
                regionalId : (vm.lotedistribuicao.regionalId)? vm.lotedistribuicao.regionalId : null,
                concursoId: (vm.lotedistribuicao.concurso)? vm.lotedistribuicao.concurso.id : null,
                valor : 0,
                bilheteInicial : vm.lotedistribuicao.bilheteInicial,
                bilheteFinal : vm.lotedistribuicao.bilheteFinal,
                motivoDistribuicao: 'X' // MOTIVO_DISTRIBUICAO_REGIONAL
               }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            AlertService.success('Lote do concurso '+vm.lotedistribuicao.concurso.label+' distribuido à Regional.');
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Problema na criação do lote!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.bilheteinvalido'){
                    msg = 'Bilhete inválido: ' + errorparam; 
                } else if (errorkey === 'error.bilhetenaocorrespondeconcurso'){
                    msg = 'Bilhete ' + errorparam + ' não corresponde ao concurso selecionado.'; 
                } else if (errorkey === 'error.loteinvalido'){
                    msg = 'Lote inválido: ' + errorparam;
                } else if (errorkey === 'error.valorloteinvalido'){
                    msg = 'Lote com valor diferente do indicado: ' + errorparam;                    
                }  else if (errorkey === 'error.sempermissao'){
                    msg = 'O usuário não tem permissão para alterar todos os bilhetes deste intervalo.';                    
                } else if (errorkey === 'error.bilhetejavalidado'){
                    msg = 'Existem bilhetes validados no intervalo informado.';                    
                } else if (errorkey === 'error.faixapertenceoutrolote'){
                    msg = 'Parte dos bilhetes já atribuidos a uma regional diferente deste estabelecimento.';
                } else if (errorkey === 'error.loteOutraRegional'){
                    msg = 'Parte dos bilhetes já atribuidos a uma outra regional.';
                }
            } 
            AlertService.error(msg);
        }


        function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.lotedistribuicao.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.lotedistribuicao.concurso);
        }             
    }
})();
