(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteRegionalChangeOwnerController', LoteRegionalChangeOwnerController);

    LoteRegionalChangeOwnerController.$inject = ['$rootScope', '$http', '$uibModalInstance', 
       'Regional', 'LoteRegional', 'AlertService', 'concurso', 'idlote', 'idregional'];

    function LoteRegionalChangeOwnerController ($rootScope, $http, $uibModalInstance, 
        Regional, LoteRegional, AlertService,  concurso, idlote, idregional) {
        var vm = this;

        vm.regionais = [];
        vm.concurso = concurso;
        vm.idlote = idlote;
        vm.idregional = idregional;

        vm.clear = clear;
        vm.save = save;

        console.log(" "+ idregional+ " "+ idlote + " %o", concurso);

        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
               if (vm.concurso){
                 LoteRegional.distribuiRegional({concursoId: vm.concurso.id, loteId: vm.idlote, regionalId: vm.idregional
                     }, onSaveSuccess, onSaveError);
               }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
                AlertService.success('Lote de distribuição alterado com sucesso');
                $rootScope.$broadcast('updateListLoteRegional');
                $uibModalInstance.close(result);
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Houve um erro na alteração do lote de distribuição de bilhetes!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.sempermissao'){
                    msg = 'Sem permissão para a ação'; 
                } else if (errorkey === 'error.lotejavendido'){
                    msg = 'Algums Bilhetes deste lote já foram entregues a um estabelecimento.'; 
                } else if (errorkey === 'error.loteinvalido'){
                    msg = 'Lote inválido! Verifique a faixa de bilhetes e o concurso selecionado';                   
                }
            } 
            AlertService.error(msg);
        }

    }
})();
