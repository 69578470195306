(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('BaseBilhetes', BaseBilhetes);

    BaseBilhetes.$inject = ['$resource'];

    function BaseBilhetes ($resource) {
        var resourceUrl =  'api/bilhetes-vendidos/1';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
