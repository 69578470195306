(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RegionalDeleteController',RegionalDeleteController);

    RegionalDeleteController.$inject = ['$rootScope','$uibModalInstance', 'entity', 'Regional','AlertService'];

    function RegionalDeleteController($rootScope, $uibModalInstance, entity, Regional, AlertService) {
        var vm = this;

        vm.regional = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Regional.delete({id: id},
                function () {
                    $rootScope.$broadcast('updateListRegional');                    
                    AlertService.success('Regional excluida com sucesso');    
                    $uibModalInstance.close(true);
                },
                function(){
                    AlertService.error("Não foi possível excluir a regional!\nVerifique se não existe um estabelecimento ou um lote de distribuição associado a ele.");
                });
        }
    }
})();
