 (function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('SorteioLocalDB', SorteioLocalDB);

    function SorteioLocalDB () {
        var databaseName =  'sorteioapp.localdb';
        var dbBilhetesName =  'sorteioapp.bilhetes.localdb';
        var db; 
        var dbBil;
       // var countBilhetes = -1;
        initDB();
        function initDB(){
           db  = new PouchDB(databaseName, {revs_limit: 1, auto_compaction: true});
           dbBil  = new PouchDB(dbBilhetesName, {revs_limit: 1, auto_compaction: true});

        };        


        function resetDB(){
            return new Promise(
                function(resolve, reject) {   
                    Promise.all([dbBil.destroy(),db.destroy()])
                            .then(function () {                                    
                                initDB();
                                resolve();
                            })
                            .catch(function(error){ reject(error);});
                });
            };

        function insertConcurso (conc){            
            return new Promise(
                function(resolve, reject) {   
                    conc._id = 'concurso/'+conc.id;
                    db.get(conc._id)
                    .then(function (doc) {
                        console.log('JA EXISTE O CONCURSO %o', conc);
                        resolve(doc);
                    })
                    .catch(function (err) {
                        resetDB()
                            .then(function () {
                                initDB();
                                db.bulkDocs([conc, {_id: 'concurso/'+conc.id+'/ultimaatualizacao', 
                                                    dataModificacao: '2000-01-01T00:00:00.000'}])
                                    .then(function () {
                                        resolve(conc);
                                    })
                                    .catch(function (err2) {
                                        reject(err2);
                                    });
                            })
                            .catch(function (err2) {
                                reject(err2);
                            });
                    });
                });
        
            };

        function querylastLote(concid, funcsucess, funcerror){
           db.allDocs({limit : 1, include_docs: true, startkey: 'concurso/'+concid+'/ultimaatualizacao', endkey: 'concurso/'+concid+'/ultimaatualizacao\uffff'})
            .then(function(res2){
                if(res2.rows && res2.rows.length > 0)
                    funcsucess(res2.rows[0].doc);                    
                else {
                    funcerror('Não existe dados sobre a ultima atualização do concurso');
                }
            }).catch(function (err){
                funcerror(err);
            });
        };

        function totalBilhetes(concid){
            return new Promise(
                function(resolve, reject) {               
                    dbBil.allDocs({limit : 0, startkey: 'bilhete/'+concid, endkey: 'bilhete/'+concid+'\uffff'})
                        .then(function(res){
                            resolve(res.total_rows);
                        }).catch(function(err){reject(err);});
                });        
        }

        function queryBilhetePorIndex(concid, idx){
            return new Promise(
                function(resolve, reject) {                           
                    dbBil.allDocs({limit : 1, include_docs: true, startkey: 'bilhete/'+concid+'/'+idx+':', endkey: 'bilhete/'+concid+'/'+idx+':\uffff'})
                        .then(function(res){           
                           if ( res.rows.length > 0){
                               resolve( res.rows[0].doc);
                           } else {
                               reject('Nenhum bilhete foi encontrado com index '+ idx);
                           }
                        })
                        .catch(function(err){ reject(err);});
                });
        }
        /*
        function queryEstabelecimento(estabelecimentoId){
            return db.get('estabelecimento/'+estabelecimentoId);
        }

        function insertVariosLotesVenda (arraylote){
            for(var a=0; a < arraylote.length; ++a){
                var lote = arraylote[a];
                lote._id = 'lotevenda/'+lote.concursoId+':'+lote.numeroInicial+"-"+lote.numeroFinal;                   
            }      
            db.bulkDocs(arraylote);
            db.allDocs({limit :0, startkey: 'lotevenda/', endkey: 'lotevenda/\uffff'})            
        };

        function insertVariosEstabelecimentos (array){
            var arrayToDelete = [];
            for(var a=0; a < array.length; ++a){
                var est = array[a];
                est._id = 'estabelecimento/'+est.id; //+':'+est.nome+"-"+est.bairro;     
                est._rev = '1-00000';
                arrayToDelete.push({_id: est._id, _rev: '1-00000', _deleted: true});                
            }      
            db.bulkDocs(arrayToDelete)
              .then(function(data){ db.bulkDocs(array, {new_edits:false});})
              .catch(function(err){ db.bulkDocs(array, {new_edits:false});});
            //db.allDocs({limit :0, startkey: 'estabelecimento/', endkey: 'estabelecimento/\uffff'})
        };        
        */
       var insertVariosBilhetesActive = false;
       function insertVariosBilhetes (array){          
            if (!insertVariosBilhetesActive) {
                insertVariosBilhetesActive = true;
                dbBil.allDocs({limit : 0, startkey: 'bilhete/', endkey: 'bilhete/\uffff'})
                    .then(function(res){
                    var arrayToDelete = [];
                    for(var a=0; a < array.length; ++a){
                        var obj = array[a];
                        var rank = res.total_rows + 1 + a;                         
                        obj._id = 'bilhete/'+obj.concursoId+'/'+rank+":"+obj.bitSet; 
                        obj._rev = '1-00000';                        
                        arrayToDelete.push({_id: obj._id, _rev: obj._rev, _deleted: true});     
                    }    
                    dbBil.bulkDocs(arrayToDelete)
                        .then(function(data){ 
                            dbBil.bulkDocs(array, {new_edits:false});
                         }).catch(function(err){ console.log(err); dbBil.bulkDocs(array, {new_edits:false});  });
                    insertVariosBilhetesActive = false;                        
                });
            } else {
                setTimeout(function(){
                    insertVariosBilhetes (array)
                }, 500);
            }
        };
  
/*
        function findAllDB(callBackSucess){
            db.allDocs({include_docs: true, descending: true}, function(err, doc) {
                //if(!err) {
                   callBackSucess(doc.rows);
                //}
             }); 
        };
*/
        var res = {
            reset: resetDB,
            insertConcurso: insertConcurso,            
            querylastLote: querylastLote,
            totalBilhetes: totalBilhetes,
            queryBilhetePorIndex: queryBilhetePorIndex,
            //queryEstabelecimento: queryEstabelecimento,
            //insertVariosLotesVenda: insertVariosLotesVenda,
            //insertVariosEstabelecimentos: insertVariosEstabelecimentos,            
            insertVariosBilhetes : insertVariosBilhetes,
            atualizar: function (obj){  db.put(obj);},
            consoleInfo: function() {
               db.info().then(function (info) {
                 console.log(info.db_name +" "+ info.doc_count);
               }).catch(function(err){console.log(err);});
            },
            db: db, // este atributo está explicito para permitir testes no console
            dbBil: dbBil
        };

        return res;
    }
})();
