(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ConcursoDetailController', ConcursoDetailController);

    ConcursoDetailController.$inject = ['$scope', 'DataUtils', 'ParseLinks', '$rootScope', '$stateParams', 'previousState', 'entity', 'Concurso'];

    function ConcursoDetailController($scope, DataUtils, ParseLinks, $rootScope, $stateParams, previousState, entity, Concurso) {
        var vm = this;

        vm.concurso = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('sorteioApp:concursoUpdate', function(event, result) {
            vm.concurso = result;
        });
        $scope.$on('$destroy', unsubscribe);


        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
    }
})();
