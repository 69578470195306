(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('concurso', {
            parent: 'entity',
            url: '/concurso',
            data: {
                authorities: ['PERM_EDICAO_CONCURSO','PERM_GERACAO_CONCURSO'],
                pageTitle: 'sorteioApp.concurso.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/concurso/concursos.html',
                    controller: 'ConcursoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('concurso');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider
        .state('concurso.adm', {
            parent: 'entity',
            url: '/concursoadm',
            data: {
                authorities: ['PERM_GERACAO_CONCURSO'],
                pageTitle: 'sorteioApp.concurso.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/concurso/concursos-adm.html',
                    controller: 'ConcursoControllerAdm',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('concurso');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })        
        .state('concurso-detail', {
            parent: 'concurso',
            url: 'get/{id}',
            data: {
                authorities: ['PERM_EDICAO_CONCURSO','PERM_GERACAO_CONCURSO'],
                pageTitle: 'sorteioApp.concurso.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/concurso/concurso-detail.html',
                    controller: 'ConcursoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('concurso');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Concurso', function($stateParams, Concurso) {
                    return Concurso.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'concurso',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('concurso-edit', {
            parent: 'concurso',
            url: 'edit/{id}',
            data: {
                authorities: ['PERM_EDICAO_CONCURSO','PERM_GERACAO_CONCURSO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concurso/concurso-dialog.html',
                    controller: 'ConcursoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Concurso', function(Concurso) {
                            console.log($stateParams);
                            return Concurso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('concurso', null, { reload: 'concurso' });
                }, function() {
                    $state.go('concurso');
                });
            }]
        })
        .state('concurso.new', {
            parent: 'concurso.adm',
            url: '/new',
            data: {
                authorities: ['PERM_GERACAO_CONCURSO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concurso/concurso-dialog.html',
                    controller: 'ConcursoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dataInicioVigencia: null,
                                dataFimVigencia: null,
                                tipo: null,
                                //geracaoIncremental: null,
                                ano:null,
                                sequencial:null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('concurso.adm', null, { reload: 'concurso.adm' });
                }, function() {
                    $state.go('concurso.adm');
                });
            }]
        })
        .state('concurso.geracao', {
            parent: 'concurso.adm',
            url: '/{id}/geracao',
            data: {
                authorities: ['PERM_GERACAO_CONCURSO']
            },params: {
               conclabel : null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concurso/concurso-geracao.html',
                    controller: 'GeracaoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity:  function () {
                            return {
                                concurso: {id : $stateParams.id, label : $stateParams.conclabel}
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('concurso.adm', null, { reload: 'concurso.adm' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
