(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RelatorioDistribuicaoLoteEstabelecimentoController', RelatorioDistribuicaoLoteEstabelecimentoController);

    RelatorioDistribuicaoLoteEstabelecimentoController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', '$cookies',
        'Concurso', 'Regional', 'AreaRegional', 'DistribuicaoBilhetes', 'Principal', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function RelatorioDistribuicaoLoteEstabelecimentoController ($timeout, $scope, $stateParams,  $http, $state, $cookies,
       Concurso, Regional, AreaRegional, DistribuicaoBilhetes, Principal, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.regionais = [];
        vm.areasregional = [];        
        vm.concursos = [];
        vm.tipos = [];        
        //vm.totalizacao =  Totalizacao.empty();        
        vm.filtro = pagingParams.filtro;
        vm.changeconcurso = changeconcurso;
        vm.changeregional = changeregional;
        vm.changearea = changearea;
        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.saveCSV = saveCSV;
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.requesting = false;

        console.log(vm.filtro);
        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);   
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            } else if (vm.regionais.length > 1){
                var regProcurado = _.find(vm.regionais, function(re){ return vm.filtro.regional && re.id ==  vm.filtro.regional.id;});
                if (vm.filtro.regional && regProcurado){
                    vm.filtro.regional  = regProcurado;
                } else {
                    vm.filtro.regional = null;
                    vm.filtro.area = null;
                }
                vm.regionais.unshift({'id': -1, 'nome': "Todas Regionais"});
            }                     
                        
            Concurso.queryshort().$promise.then(function(res){
                vm.concursos = res;                
                if(vm.concursos.length > 0) {
                    if( vm.filtro.concurso == null){
                        selecionaConcursoPadrao();
                    }
                    changeconcurso();
                    loadAll();                    
                }
            });            
        });        



        Concurso.queryTiposBilhetes().$promise.then(function(res){
            vm.tipos = res;
        });        

        function changeconcurso(){
            salvaConncursoCookie();
            changeregional();
        }
        function changeregional(){
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }               
 
            if (vm.filtro.regional && vm.filtro.regional.id>0){
                AreaRegional.queryPorRegional({'regionalId': vm.filtro.regional.id }).$promise.then(function(res){
                    vm.areasregional = _.orderBy(res, ['nome'], ['asc']);
                    var areaProcurada = _.find(vm.areasregional, function(ar){ return vm.filtro.area && ar.id ==  vm.filtro.area.id;});                        
                    if(areaProcurada){
                        vm.filtro.area  = areaProcurada;
                    } else {
                        vm.filtro.area = null;
                    }                    
                });
            } else {
                vm.areasregional = [];
                vm.filtro.area = null;                
            }            
            changearea();
        }
        function changearea(){
            if (!vm.filtro.regional){
                vm.areasregional = [];
            }
        }        

        function saveCSV(){
            requestData(onSuccessSaveCSVRequest, 0, 10000000);
        }    
        function search(){
            vm.page = 1;
            requestData(onSuccessSearchRequest, 0, vm.itemsPerPage);            
        }
        function loadAll () {
            requestData(onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
        }

        function requestData (successFunction, startPage, pageSize) {
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }   
           if (vm.filtro.regional) {
                vm.requesting = true;
                DistribuicaoBilhetes.queryDistribuicaoEstabelecimentoPorRegional({
                        concursoId: vm.filtro.concurso.id,
                        regionalId: (vm.filtro.regional.id>0)? vm.filtro.regional.id : null,
                        areaId: (vm.filtro.area)? vm.filtro.area.id : null,
                        page: startPage,
                        size: pageSize,
                        sort: sort()
                    }, successFunction, onError);
            } else {
                vm.page = 1;
                vm.totalItems = 1;
                vm.queryCount = 1;
                vm.links = {
                    last: 0
                };                
                vm.lotebilhetes = [];              
            }            
           function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onError(error) {
                vm.requesting = false;
                AlertService.error(error.data.message);
            }
        }

        function onSuccessSearchRequest(data, headers) {
            vm.requesting = false;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;     
             vm.lotebilhetes = [];
            for (var i = 0; i < data.length; i++) {
                vm.lotebilhetes.push(data[i]);
            }
        }
        function onSuccessSaveCSVRequest(data, headers) {
            vm.requesting = false;
            var lista = data;
            _.sortBy(lista, ['id', 'bilheteInicial']);
            var content = 'Concurso;Número Inicial;Número Final;Quantidade;';
            content += 'Estabelecimento;Área;Regional\n';
            for (var i = 0; i < lista.length; i++) {
                content += (lista[i].concurso.label).replace(/[,;\n]/g,'')+';';
                content += (lista[i].bilheteInicial).replace(/[,;\n]/g,'')+';';
                content += (lista[i].bilheteFinal).replace(/[,;\n]/g,'')+';';
                content += lista[i].quantidade+';';
                content += ((lista[i].estabelecimento != null)?lista[i].estabelecimento.nome:'').replace(/[,;\n]/g,'')+';';
                content += ((lista[i].estabelecimento != null && lista[i].estabelecimento.areaRegional != null)?lista[i].estabelecimento.areaRegional.nome:'').replace(/[,;\n]/g,'')+';';
                content += (lista[i].regional.nome).replace(/[,;\n]/g,'')+'\n';
            }
            //var hiddenElement = document.createElement('a');
            //hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
            //hiddenElement.download = 'relatorio_dist_estabelecimentos'+vm.filtro.concurso.id+'.csv';
            //document.body.appendChild(hiddenElement);
            //hiddenElement.click(); 
            var fileName = 'relatorio_dist_estabelecimentos'+vm.filtro.concurso.id+'.csv';
            var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
            var csvContentEncoded = textEncoder.encode([content]);
            var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
            saveAs(blob, fileName);                                                           
        }

        function reset () {
            vm.page = 1;
            vm.filtro.regional = null;
            //vm.totalizacao = Totalizacao.create(vm.tipos);
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }        
    }
})();
