(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteRegionalDeleteController',LoteRegionalDeleteController);

    LoteRegionalDeleteController.$inject = ['$rootScope','$uibModalInstance', 'concurso', 
    'idlote', '$http', 'AlertService'];

    function LoteRegionalDeleteController($rootScope, $uibModalInstance, concurso,
         idlote, $http, AlertService) {
        var vm = this;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.idlote = idlote;
        vm.concurso = concurso;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            if (vm.concurso){
              $http.delete('api/lotesregionais/'+vm.concurso.id+'/'+vm.idlote).then(
                function () {
                    AlertService.success('Lote de distribuição removido com sucesso');
                    $rootScope.$broadcast('updateListLoteRegional');
                    $uibModalInstance.close(true);
                }, function(){
                    AlertService.error("Erro na remoção do lote! Verifique se existem lotes de venda relacionados a este.");
                });       
           }         
        }
    }
})();
