(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('Lancamento', Lancamento);

    Lancamento.$inject = ['$resource', 'DateUtils'];

    function Lancamento ($resource, DateUtils) {
        var resourceUrl =  'api/lancamentos/:id';
        var resourceTotalizacaoUrl = 'api/lancamentos/totalizacao/:concid';
        var resPrincipal = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataLancamento = DateUtils.convertDateTimeFromServer(data.dataLancamento);
                        console.log("GET");
                        console.log(data);
                    }
                    return data;
                }                
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    console.log(data);
                    copy.dataLancamento = DateUtils.convertDateTimeFromServer(copy.dataLancamento);
                    return angular.toJson(copy);
                }            
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataLancamento = DateUtils.convertDateTimeFromServer(copy.dataLancamento);
                    return angular.toJson(copy);
                }            

            },
            'delete': {
                method: 'DELETE'
            }
        });
        var resTotalizacao  = $resource(resourceTotalizacaoUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });

      
        return {
            query  : resPrincipal.query,
            get    : resPrincipal.get,
            update : resPrincipal.update,
            save   : resPrincipal.save,
            delete : resPrincipal.delete,
            totalizacao : resTotalizacao.query
        };
    }
})();
