(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('estabelecimento', {
            parent: 'entity',
            url: '/estabelecimento?page&sort',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO','PERM_CONSULTAR_ESTABELECIMENTO'],
                pageTitle: 'sorteioApp.estabelecimento.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/estabelecimento/estabelecimentos.html',
                    controller: 'EstabelecimentoController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'nome,asc',
                    squash: true
                },
                filtro: {nome: null, bairro: null, uf:null, cidade: null, telefone: null, regional : null }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }], 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('estabelecimento');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('estabelecimento-detail', {
            url: '/estabelecimento/{id}',
            parent: 'entity',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO','PERM_CONSULTAR_ESTABELECIMENTO'],
                pageTitle: 'sorteioApp.estabelecimento.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-detail.html',
                    controller: 'EstabelecimentoDetailController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'loteRegionalId,asc',
                    squash: true
                },
                filtro: {concurso : null }
            },
            resolve: {
                pagingParams: ['$state', '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                    //if ($state.current.name === 'estabelecimento-detail') 
                     return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                    //else return {page: 1, sort: 'id,asc'}
                }],                 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('estabelecimento');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Estabelecimento', function($stateParams, Estabelecimento) {
                    return Estabelecimento.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'estabelecimento',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('estabelecimento-detail.edit', {
            parent: 'estabelecimento-detail',
            url: '/detail/edit',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-dialog.html',
                    controller: 'EstabelecimentoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Estabelecimento', function(Estabelecimento) {
                            return Estabelecimento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('estabelecimento.new', {
            parent: 'estabelecimento',
            url: '/new',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-dialog.html',
                    controller: 'EstabelecimentoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                cpf: null,
                                nome: null,
                                email: null,
                                telefone: null,
                                logradouro: null,
                                numero: null,
                                bairro: null,
                                cidade: null,
                                uf:null,
                                cep: null,
                                pontoReferencia: null,
                                ativo: true,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('estabelecimento', null, { reload: 'estabelecimento' });
                }, function() {
                    $state.go('estabelecimento');
                });
            }]
        })
        .state('estabelecimento.edit', {
            parent: 'estabelecimento',
            url: '/{id}/edit',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-dialog.html',
                    controller: 'EstabelecimentoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Estabelecimento', function(Estabelecimento) {
                            return Estabelecimento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('estabelecimento', null, { reload: 'estabelecimento' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('estabelecimento.delete', {
            parent: 'estabelecimento',
            url: '/{id}/delete',
            data: {
                authorities: ['PERM_CADASTRO_ESTABELECIMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-delete-dialog.html',
                    controller: 'EstabelecimentoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Estabelecimento', function(Estabelecimento) {
                            return Estabelecimento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('estabelecimento', null, { reload: 'estabelecimento' });
                }, function() {
                    $state.go('^');
                });
            }] 
        })
        .state('estabelecimento.venda', {
            parent: 'estabelecimento',
            url: '/{id}/venda',
            data: {
                authorities: ['PERM_DISTRIBUICAO_ESTABELECIMENTO']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/estabelecimento/estabelecimento-venda.html',
                    controller: 'VendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  function () {
                            return {
                                estabelecimentoId: $stateParams.id
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('estabelecimento', null, { reload: 'estabelecimento' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;        
    }

})();
