(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('LoteEstabelecimento', LoteEstabelecimento);

    LoteEstabelecimento.$inject = ['$resource'];

    function LoteEstabelecimento ($resource) {
        var urlResourceLotesEstabelecimentoConsultaConcursoRegional = 'api/lotevenda/concurso-regional/:concursoId/:regionalId';        
        var urlResourceLotesEstabelecimentoConsultaConcursoEstabelecimento = 'api/lotevenda/concurso-estabelecimento/:concursoId/:estabelecimentoId';
        var urlResourceDistribuiEstabelecimento = 'api/lotevenda';
        var urlResourceChangeEstabelecimento = 'api/lotevenda/changeestasbelecimento/:concursoId/:loteId/:estabelecimentoId';

        function getQueryResurce(url){
            return $resource(url, {}, {
                    'query': {method: 'GET', isArray: true}
                }).query;
        } 
        
             
     
        return {
            queryPorConcursoRegional: getQueryResurce(urlResourceLotesEstabelecimentoConsultaConcursoRegional),
            queryPorConcursoEstabelecimento: getQueryResurce(urlResourceLotesEstabelecimentoConsultaConcursoEstabelecimento),
            distribuiEstabelecimento : $resource(urlResourceDistribuiEstabelecimento, {}, {
                'distribui': {method: 'POST'} }).distribui,
            changeEstabelecimento : $resource(urlResourceChangeEstabelecimento, {}, {
                    'change': {method: 'GET'} }).change,                
            query : getQueryResurce(urlResourceDistribuiEstabelecimento)                
        }

    }
})();
