(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lote-estabelecimento', {
            parent: 'bilhete',
            url: '/lote-estabelecimento?page&sort',
            data: {
                authorities: ['PERM_CONFECCAO_LOTES'],
                pageTitle: 'sorteioApp.lote-estabelecimento.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lote-estabelecimento/lotes-estabelecimento.html',
                    controller: 'LoteEstabelecimentoController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {concurso: null, regional:null }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('lote-estabelecimento.changeowner', {
            parent: 'lote-estabelecimento',
            url: '/edit',
            params : {'idconcurso': null, 'idlote': null, 'idregional': null, 'idestabelecimento': null},
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-estabelecimento/lote-estabelecimento-dialog.changeowner.html',
                    controller: 'LoteEstabelecimentoChangeOwnerController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        idconcurso: $stateParams.idconcurso,
                        idlote: $stateParams.idlote,
                        idregional: $stateParams.idregional,
                        idestabelecimento: $stateParams.idestabelecimento                                        
                    }                    
                }).result.then(function() {
                    $state.go('lote-estabelecimento', null, { reload: 'lote-estabelecimento', notify: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })   
        .state('lote-estabelecimento.delete', {
            parent: 'lote-estabelecimento',
            url: '/delete',
            params : {'concurso': null, 'idlote': null},
            data: {
                authorities: ['PERM_CONFECCAO_LOTES']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lote-estabelecimento/lote-estabelecimento-delete-dialog.html',
                    controller: 'LoteEstabelecimentoDeleteController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        concurso: $stateParams.concurso,
                        idlote: $stateParams.idlote
                    }                    
                }).result.then(function() {
                    $state.go('lote-estabelecimento', null, { reload: 'lote-estabelecimento' });
                }, function() {
                    $state.go('^', null, { reload: 'lote-estabelecimento' });
                });
            }]
        })

        ;
    }

})();
