(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('LoteRegional', LoteRegional);

    LoteRegional.$inject = ['$resource'];

    function LoteRegional ($resource) {
        var urlResourceLotesRegionais = 'api/lotesregionais';
        var urlResourceLotesRegionaisNaoAssociados = 'api/lotesregionais/concurso/livres/:concursoId';
        var urlResourceLotesRegionaisConsultaConcursoRegional = 'api/lotesregionais/concurso-regional/:concursoId/:regionalId';        
        var urlResourceDistribuiLotesRegional = 'api/lotesregionais/distribui/:concursoId/:loteId/:regionalId';        
        var urlResourceFaixasDisponiveisLoteRegional = 'api/lotesregionais/faixas-disponiveis/:concursoId/:regionalId';

        var resourceprincipal = $resource(urlResourceLotesRegionais, {}, {
            'query': {method: 'GET', isArray: true},
            'save':  {method:'POST', isArray: false}
        });

        function getQueryResurce(url){
            return $resource(url, {}, {
                    'query': {method: 'GET', isArray: true}
                }).query;
        } 
        
             
     
        return {
            queryPorConcursoSemRegional: getQueryResurce(urlResourceLotesRegionaisNaoAssociados),
            queryPorConcursoRegional: getQueryResurce(urlResourceLotesRegionaisConsultaConcursoRegional),
            queryFaixasDisponiveis: getQueryResurce(urlResourceFaixasDisponiveisLoteRegional),
            distribuiRegional: $resource(urlResourceDistribuiLotesRegional, {}, {
                            'distribui': {method: 'GET'} }).distribui,
            query : resourceprincipal.query,
            save : resourceprincipal.save
        }

    }
})();
