(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('SorteioController', SorteioController);

    SorteioController.$inject = ['$scope', '$state', '$http', 'DataUtils', 'SorteioLocalDB', 
            'Concurso', 'BaseBilhetes',  'ParseLinks', 'AlertService', 'paginationConstants'];

    function SorteioController($scope, $state, $http, DataUtils, SorteioLocalDB,
                 Concurso,  BaseBilhetes, ParseLinks, AlertService, paginationConstants) {

        var vm = this;
        vm.concurso ;
        vm.concursos = [];        

        Concurso.query().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0){
                vm.concurso=vm.concursos[0];
            }
        });

       function recuperaTodasPaginas(queryPage, options, callbackRows){            
          var walkPageAndStore = function (pageOptions, offset, resolve, reject){
              var fail = false;
              queryPage (pageOptions, function (res) {
                var total = (res.headers)? res.headers('X-Total-Count'): res.data.length;
                callbackRows(res.data);
                offset = offset + res.data.length;                
                if( offset < total && res.data.length != 0){
                    pageOptions.page = pageOptions.page + 1;
                    walkPageAndStore(pageOptions, offset, resolve, reject);
                } else if( offset == total){
                    resolve(offset);
                } else {
                    console.log('Não foi possível buscar todas as informações '+offset+'/'+total);
                    reject('Não foi possível buscar todas as informações '+offset+'/'+total);   
                }
              } , function onError(error) {
                  console.log(error);
                 reject(error);
              });
            };
          var p1 = new Promise(
                function(resolve, reject) {    
                    walkPageAndStore(options, 0, resolve, reject);
                });
          return p1;
       }
                 
        vm.resetLocalDB = function (){
            SorteioLocalDB.reset()
                   .then(function(){
                       AlertService.success('Base local apagada.');  $scope.$apply()})
                   .catch(function(err){
                     AlertService.error('Houve um problema para apgar a base local %o.', err); $scope.$apply();});
        }; 
        vm.infoLocalDB = function (){
            SorteioLocalDB.consoleInfo();
        };
                         
        vm.refreshLocalDB = function (){
            SorteioLocalDB.insertConcurso(vm.concurso)
                .then(function(){
                    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0,-1);
                    console.log('valor para atualizacao %o',localISOTime);
                    SorteioLocalDB.querylastLote(vm.concurso.id, 
                        function(ultimamod){                    
                            console.log("REFRESH DB %o",ultimamod);
                            var funcgetbilhetesVendidos = function ( opt, onSaveSuccess, onSaveError ){
                                var req = {
                                method: 'GET',
                                url: 'api/bilhetesvendidos/ultimos/'+opt.concursoId+'/'+ultimamod.dataModificacao+'?page='+opt.page+'&size='+opt.size+'&sort='+opt.sort,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                                };
                                $http(req).then(onSaveSuccess, onSaveError);
                            }; 
                            /*
                            var funcgetlotesVendas = function ( opt, onSaveSuccess, onSaveError ){
                                var req = {
                                method: 'GET',
                                url: 'api/lotebilhete-venda/'+opt.concursoId+'/'+ultimamod.dataModificacao+'?page='+opt.page+'&size='+opt.size+'&sort='+opt.sort,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                                };
                                $http(req).then(onSaveSuccess, onSaveError);
                            };
                            var funcgetEstabelecimentos  = function ( opt, onSaveSuccess, onSaveError ){
                                var req = {
                                method: 'GET',
                                url: 'api/estabelecimentos-ultimos/'+ultimamod.dataModificacao+'?page='+opt.page+'&size='+opt.size+'&sort='+opt.sort,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                                };
                                $http(req).then(onSaveSuccess, onSaveError);
                            }; 
                    
                            var p1 = recuperaTodasPaginas(funcgetlotesVendas, {
                                        concursoId: vm.concurso.id,
                                        page: 0,
                                        size: 2000,
                                        sort: 'dataModificacao,asc'
                                    }, SorteioLocalDB.insertVariosLotesVenda);

                            var p2 = recuperaTodasPaginas(funcgetEstabelecimentos, {
                                            page: 0,
                                            size: 2000,
                                            sort: 'id,asc'
                                        }, SorteioLocalDB.insertVariosEstabelecimentos);  
                            */
                            var p3 = recuperaTodasPaginas(funcgetbilhetesVendidos, {
                                            concursoId: vm.concurso.id,
                                            page: 0,
                                            size: 2000,
                                            sort: 'id,asc'
                                        }, SorteioLocalDB.insertVariosBilhetes);    
                                        
                            Promise.all([p3]).then( function(){ 
                                ultimamod.dataModificacao = localISOTime;
                                SorteioLocalDB.atualizar(ultimamod);
                                AlertService.success('Base local atualizada.');
                                $scope.$apply();
                            }).catch(function(error){ 
                                AlertService.error('Houve um problema para atualizar a base local.');
                                $scope.$apply();
                            });
                     }, function (err){ 
                        console.log('ERROR %o', err);
                        AlertService.info('Tente atualizar a base novamente.');});
                        $scope.$apply();
                }).catch(function(err){
                    console.log('ERROR %o', err);
                    AlertService.info('Tente atualizar a base novamente.');
                    $scope.$apply();
                });
        };

/*
        function loadAll () {
            BaseBilhetes.query({
                concursoid: 1,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: null
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.bilhetes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
*/        
    }
})();
