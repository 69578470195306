(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ValidacaoBilheteDialogController', ValidacaoBilheteDialogController);

        ValidacaoBilheteDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$cookies',
       'Concurso', 'AlertService', 'Bilhete'];

    function ValidacaoBilheteDialogController ($timeout, $scope, $stateParams,  $http, $cookies,
        Concurso, AlertService, Bilhete) {
        var vm = this;

        vm.concursos = [];
        vm.validacao = {concurso: null, bilhete: null};
        vm.lastValidations = [];
        vm.alerts = [];        
        vm.limpar = limpar;
        vm.validar = validar;
        vm.changeconcurso = changeconcurso;
        
        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
          });
        };

        Concurso.querynaosinc().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0){
                selecionaConcursoPadrao();
            }
        });

        function changeconcurso(){
            salvaConncursoCookie();
            vm.validacao.bilhete = null;
        }

       function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };

        function limpar () {
            vm.validacao.concurso=null;
            vm.validacao.bilhete = null;
        }

        function validar () {
            vm.isSaving = true;
            if (vm.validacao.bilhete != null && vm.validacao.concurso){
                Bilhete.validar({concurso: vm.validacao.concurso.id,                      
                    bilhete : vm.validacao.bilhete
                }).$promise.then( onSaveSuccess).catch(onSaveError);
            }
        }

        function onSaveSuccess (result) {
           // $scope.$emit('sorteioApp:geracaoUpdate', result);
            vm.isSaving = false;
            vm.lastValidations.push ({bilhete: vm.validacao.bilhete, date: Date.now()});
            if (vm.lastValidations.length > 10){
                vm.lastValidations.shift();
            }
            AlertService.success('Bilhete '+ vm.validacao.bilhete +' validado!');
            vm.validacao.bilhete = null;

            positionFirstInput();
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Houve um erro na validacao do bilhetes!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.bilheteInexistente'){
                    msg = 'Bilhete inválido: ' + errorparam; 
                } else if (errorkey === 'error.faixaNenhumLote'){
                    msg = 'Bilhete não pertence a um lote entregue a uma regional.';  
                } else if (errorkey === 'error.periodoinvalido'){
                    msg = 'Fora do hoŕario de validação: ' + errorparam;  
                } else if (errorkey === 'error.javalidado'){
                    msg = 'Bilhete já validado : ' + errorparam;  
                }   

                
            } 
            AlertService.error(msg);      
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.validacao.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.validacao.concurso);
        }          
    }
})();
