(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('HistoricoDistribuicaoBilhetesController', HistoricoDistribuicaoBilhetesController);

        HistoricoDistribuicaoBilhetesController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', '$cookies',
       'Concurso', 'Regional', 'AlertService', 'DistribuicaoBilhetes', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function HistoricoDistribuicaoBilhetesController ($timeout, $scope, $stateParams,  $http, $state, $cookies,
        Concurso, Regional, AlertService, DistribuicaoBilhetes, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.concursos = [];
        vm.alerts = [];        
        vm.loadAll = loadAll;
        vm.changeconcurso = changeconcurso;
        vm.filtro = pagingParams.filtro;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;            
        
        function positionFirstInput(){
          $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
          });
        };

        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.filtro.concurso == null){
                selecionaConcursoPadrao();
                changeconcurso();
            }
        });
        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }
            loadAll ();
        });        

        function changeconcurso(){
            salvaConncursoCookie();    
        }

       function addErrorAlert(mensagem) {
            vm.alerts.push({type: 'danger', msg: mensagem});
            $timeout(function () {
                  vm.closeAlert(vm.alerts.length-1);
            }, 5000);
        };

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };
        function reset () {
            vm.page = 0;
            vm.filtro.regional = null;
            loadAll();
        }
        function loadPage(page) {
            vm.page = page;
            loadAll();
        }        
        function  loadAll (){
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }                  
            DistribuicaoBilhetes.queryLogDistribuicaoBilhetes(
                {'concursoId': vm.filtro.concurso.id,
                 page: vm.page-1, size: vm.itemsPerPage, regionalId: (vm.filtro.regional)?vm.filtro.regional.id:null},
             function(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;                
                vm.logdistribuicao = data;
             }, function(){
               vm.logdistribuicao = [];
               AlertService.error("Não foi possível coletar o log de distribuições");
             });
        }
        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: 'id,asc',
                search: vm.currentSearch
            });
        }   
        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }          
    }
})();
