(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RelatorioRegionalEstabelecimentoEnderecoController', RelatorioRegionalEstabelecimentoEnderecoController);

    RelatorioRegionalEstabelecimentoEnderecoController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', 
        'Regional', 'Estabelecimento', 'ParseLinks', 'Principal', 'AlertService', 'pagingParams', 'paginationConstants'];

    function RelatorioRegionalEstabelecimentoEnderecoController ($timeout, $scope, $stateParams,  $http, $state, 
        Regional, Estabelecimento, ParseLinks, Principal, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.regionais = [];
        vm.filtro = pagingParams.filtro;
        vm.estabelecimentos = [];
        vm.changeregional = changeregional;
        vm.enderecoCompleto = enderecoCompleto;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.saveCSV = saveCSV;      
        vm.search = search;  
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'nome';
        vm.reset = reset;
        vm.reverse = true;


        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }             
        });

        changeregional();
        function changeregional(){     
            loadAll();
        }

        function saveCSV(){
            requestData(onSuccessSaveCSVRequest, 0, 10000000);
        }    
        function search(){
            vm.page = 1;
            requestData(onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);            
        }
        function loadAll () {
            requestData(onSuccessSearchRequest,vm.page - 1, vm.itemsPerPage);
        }

        function requestData (successFunction, startPage, pageSize) {        
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }                
            Estabelecimento.queryPorRegional({
                regionalId: (vm.filtro.regional)? vm.filtro.regional.id : null,
                page: startPage,
                size: pageSize,
                sort: sort()
            }, successFunction, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'nome') {
                    result.push('nome');
                }
                return result;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function onSuccessSearchRequest(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;                 
             vm.estabelecimentos = [];
            for (var i = 0; i < data.length; i++) {
                vm.estabelecimentos.push(data[i]);
            }
        }

        function onSuccessSaveCSVRequest(data, headers) {             
            var lista = data;
            _.sortBy(lista, ['regional.nome', 'nome']);
            var content = 'Regional;Nome Fantasia;Telefone;Endereço;Situação\n';
            for (var i = 0; i < lista.length; i++) {
                content += (lista[i].regional.nome).replace(/[,;\n]/g,'')+';';
                content += (lista[i].nome).replace(/[,;\n]/g,'')+';';
                content += ((lista[i].telefone)?lista[i].telefone:'').replace(/[,;\n]/g,'')+';';
                content += (vm.enderecoCompleto(lista[i])).replace(/[,;\n]/g,'')+';';
                content += (lista[i].ativo?'Ativo':'Inativo')+'\n';
            }
            //var hiddenElement = document.createElement('a');
            //hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
            //hiddenElement.download = 'relatorio_estabelecimentos_regionais.csv';
            //document.body.appendChild(hiddenElement);
            //hiddenElement.click(); 
            var fileName = 'relatorio_estabelecimentos_regionais.csv';
            var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
            var csvContentEncoded = textEncoder.encode([content]);
            var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
            saveAs(blob, fileName);                              
        }

        function reset () {
            vm.page = 1;
            vm.filtro.regional = null;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function enderecoCompleto(estabelecimento){
            var endereco = '';
            if (estabelecimento.logradouro){
                endereco += estabelecimento.logradouro + ', ';
                if (estabelecimento.numero){
                    endereco += estabelecimento.numero + ', ';
                }
            }
            endereco += estabelecimento.bairro + ', '+ estabelecimento.cidade +  ', '+ estabelecimento.uf;
            return endereco;
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }             
    }
})();
