(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LancamentoDialogController', LancamentoDialogController);

    LancamentoDialogController.$inject = ['Concurso', 'GrupoLancamento', '$cookies', '$timeout', '$scope', '$state','$stateParams', '$uibModalInstance', 'entity', 'Lancamento'];

    function LancamentoDialogController (Concurso, GrupoLancamento, $cookies, $timeout, $scope, $state, $stateParams, $uibModalInstance, entity, Lancamento) {
        var vm = this;
        vm.tiposOperacao = [];        
        vm.gruposLancamento = [];
        vm.lancamento = entity;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.clear = clear;
        vm.save = save;
        vm.changeTipoOperacao = changeTipoOperacao;        
        vm.changevalor = changevalor;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        GrupoLancamento.queryTipos().$promise.then(function(res){
            vm.tiposOperacao = _.orderBy(res, ['nome'], ['asc']);
            for (var k=0; k < vm.tiposOperacao.length; k++){
                if(vm.lancamento && vm.lancamento.grupoLancamento && 
                vm.lancamento.grupoLancamento.tipoOperacao.id === vm.tiposOperacao[k].id )
                  vm.lancamento.tipoOperacao = vm.tiposOperacao[k];
            }
            changeTipoOperacao();
        });

        //GrupoLancamento.query().$promise.then(function(res){
        //    vm.gruposLancamento = _.orderBy(res, ['nomeGrupo'], ['asc']);
        //});        

         function changeTipoOperacao(){
            if (vm.lancamento.tipoOperacao){ 
                GrupoLancamento.queryPorTipo({idtipo:vm.lancamento.tipoOperacao.id}).$promise.then(function(res){
                    vm.gruposLancamento = _.orderBy(res, ['nomeGrupo'], ['asc']);
                    for (var k=0; k < vm.gruposLancamento.length; k++){
                        if(vm.lancamento && vm.lancamento.grupoLancamento && 
                        vm.lancamento.grupoLancamento.id === vm.gruposLancamento[k].id )
                        vm.lancamento.grupoLancamento = vm.gruposLancamento[k];
                    }
                });             
            }
         }
         

         function changevalor(){
            var ret=(vm.lancamento.valor)?vm.lancamento.valor.toString().replace(",","."):null;
            vm.lancamento.valor = ret;
         }              

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            console.log('SAVe');
            console.log(vm.lancamento);            
            if (vm.lancamento.id !== null) {
                Lancamento.update(vm.lancamento, onSaveSuccess, onSaveError);
            } else {
                Lancamento.save(vm.lancamento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:LancamentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataLancamento = false;
        
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
