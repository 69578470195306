(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('SorteioDiarioController', SorteioDiarioController);

    SorteioDiarioController.$inject = ['$scope', '$state', 'SorteioLocalDB', '$stateParams', 'entity', 'AlertService'];

    function SorteioDiarioController($scope, $state, SorteioLocalDB, $stateParams, entity, AlertService) {
        var vm = this;
        vm.concurso = entity;
        vm.numerosSorteados = [];        
        console.log("CONCURSO %o",vm.concurso);

        function nextRandom(randomIdxs, total) {
            while (randomIdxs.length != 15) {
                var idx = Math.floor( Math.random() * total + 1) ;
                var found = false;
                for(var a =0; a < randomIdxs.length; a++){
                    if (randomIdxs[a] == idx) {
                        found = true; break;
                    }
                }
                if (!found) {
                    randomIdxs.push(idx);
                    return idx;
                }
            }                    
        };
        vm.sortear = function(){
            SorteioLocalDB.totalBilhetes()
                .then(function(totalBilhetes){           
                    console.log("TOTAL %d", totalBilhetes);
                    vm.numerosSorteados = [];                             
                    var randomIdxs = [];                    
                    function funcNextRandomBilhete (){
                        var quant = 15;
                        if (quant > totalBilhetes) quant = totalBilhetes;
                        if (vm.numerosSorteados.length < quant){
                            var idx = nextRandom(randomIdxs, totalBilhetes);
                            console.log("IDX %d", idx);
                            SorteioLocalDB.queryBilhetePorIndex(vm.concurso.id, idx)
                            .then(function(doc){       
                                    vm.numerosSorteados.push(doc);
                                    $scope.$apply();
                                    funcNextRandomBilhete();
                            }).catch(function(err){
                                    console.log(err);
                            });
                        }
                    };
                    funcNextRandomBilhete();       
            });
       }
    }
})();
