(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('relatorio-distribuicao-lote-regional', {
            parent: 'app',
            url: '/relatorio-distribuicao-lote-regional',
            data: {
                authorities: ['PERM_REL_DIST_REGIONAIS'],
                pageTitle: 'sorteioApp.loteregional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/distribuicao-lote-regional.html',
                    controller: 'RelatorioDistribuicaoLoteRegionalController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'regional.nome,asc',
                    squash: true
                },
                filtro: {concurso : null, regional : null}
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('relatorio-distribuicao-regional-sem-estabelecimento', {
            parent: 'app',
            url: '/relatorio-distribuicao-regional-sem-estabelecimento',
            data: {
                authorities: ['PERM_REL_DIST_REGIONAIS_SEM_EST'],
                pageTitle: 'sorteioApp.loteregional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/distribuicao-regional-sem-estabelecimento.html',
                    controller: 'RelatorioDistribuicaoRegionalSemEstabelecimentoController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'regional.nome,asc',
                    squash: true
                },
                filtro: {concurso : null, regional : null}
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }) 
        .state('relatorio-regional-estabelecimento-endereco', {
            parent: 'app',
            url: '/relatorio-regional-estabelecimento-endereco',
            data: {
                authorities: ['PERM_REL_REG_EST_ENDERECO'],
                pageTitle: 'sorteioApp.loteregional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/regional-estabelecimento.html',
                    controller: 'RelatorioRegionalEstabelecimentoEnderecoController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {regional : null}
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })               
        .state('relatorio-distribuicao-lote-estabelecimento', {
            parent: 'app',
            url: '/relatorio-distribuicao-lote-estabelecimento',
            data: {
                authorities: ['PERM_REL_DIST_ESTABELECIMENTO'],
                pageTitle: 'sorteioApp.loteregional.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/distribuicao-lote-estabelecimento.html',
                    controller: 'RelatorioDistribuicaoLoteEstabelecimentoController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {concurso : null, regional : null, area: null}
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })    
        .state('relatorio-totalizacao-regional', {
            parent: 'app',
            url: '/relatorio-totalizacao-regional',
            data: {
                authorities: ['PERM_REL_FINANCEIRO'],
                pageTitle: 'sorteioApp.relatorios.totalizacao_regionaiss.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/totalizacao-regional.html',
                    controller: 'TotalizacaoRegionalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })      
        .state('relatorio-totalizacao-estabelecimentos', {
            parent: 'app',
            url: '/relatorio-totalizacao-estabelecimentos',
            data: {
                authorities: ['PERM_REL_FINANCEIRO'],
                pageTitle: 'sorteioApp.relatorios.totalizacao_estabelecimentos.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/totalizacao-estabelecimentos.html',
                    controller: 'TotalizacaoEstabelecimentosController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })                         
        .state('relatorio-totalizacao-lancamento', {
            parent: 'app',
            url: '/relatorio-totalizacao-lancamento',
            data: {
                authorities: ['PERM_REL_LANCAMENTO'],
                pageTitle: 'sorteioApp.relatorios.totalizacao_lancamentos.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/totalizacao-lancamentos.html',
                    controller: 'TotalizacaoLancamentoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('loteregional');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })      
        ;
    }

})();
