(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteEstabelecimentoChangeOwnerController', LoteEstabelecimentoChangeOwnerController);

    LoteEstabelecimentoChangeOwnerController.$inject = ['$rootScope', '$http', '$uibModalInstance', 
       'Estabelecimento', 'LoteEstabelecimento', 'AlertService', 'idconcurso', 'idlote',
        'idregional', 'idestabelecimento'];

    function LoteEstabelecimentoChangeOwnerController ($rootScope, $http, $uibModalInstance, 
        Estabelecimento, LoteEstabelecimento, AlertService,  idconcurso, idlote, 
        idregional, idestabelecimento) {
        var vm = this;

        vm.estabelecimentos = [];
        vm.idconcurso = idconcurso;
        vm.idlote = idlote;
        vm.idregional = idregional;
        vm.idestabelecimento = idestabelecimento;

        vm.clear = clear;
        vm.save = save;


        Estabelecimento.queryTodosAtivosPorRegional({'regionalId':idregional}).$promise.then(function(res){
            vm.estabelecimentos = _.orderBy(_.filter(res, function(est){ return est.ativo; }), 
                                    ['nome'], ['asc']);
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
               if (vm.idconcurso){
                 LoteEstabelecimento.changeEstabelecimento({concursoId: vm.idconcurso, loteId: vm.idlote, estabelecimentoId: vm.idestabelecimento
                     }, onSaveSuccess, onSaveError);
               }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
                AlertService.success('Lote de distribuição alterado com sucesso');
                $rootScope.$broadcast('updateListLoteEstabelecimento');
                $uibModalInstance.close(result);
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Houve um erro na alteração do lote de distribuição de bilhetes!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.sempermissao'){
                    msg = 'Sem permissão para a ação'; 
                } else if (errorkey === 'error.lotejavendido'){
                    msg = 'Algums Bilhetes deste lote já foram entregues a um estabelecimento.'; 
                } else if (errorkey === 'error.loteinvalido'){
                    msg = 'Lote inválido! Verifique a faixa de bilhetes e o concurso selecionado';                   
                }
            } 
            AlertService.error(msg);
        }

    }
})();
