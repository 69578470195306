(function() {
    'use strict';


    angular
        .module('TrocoPublicoApp')
        .controller('JogadorController', JogadorController);

    JogadorController.$inject = ['$http', '$timeout', 'AlertService'];

    function JogadorController($http, $timeout, AlertService) {

        var vm = this;       
        vm.jogador = {nome: null, cpf: null, telefone: null, identificacao: null};
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function save () {
                vm.isSaving = true;
                var req = {
                    method: 'POST',
                    url: 'api/jogador',
                    headers: { 'Content-Type': 'application/json'},
                    data: vm.jogador
                };

                $http(req).then( onSaveSuccess).catch( onSaveError);
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            AlertService.success('Jogador '+vm.jogador.nome + ' cadastrado com sucesso');            
        }

        function onSaveError (response) {
            vm.isSaving = false;

            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                if (errorkey === 'error.nomeInvalido'){
                   AlertService.error('Nome do jogador vazio.');
                } else if (errorkey === 'error.bilheteInexistente'){
                   AlertService.error('Bilhete inválido ou inexistente.'); 
                } else if (errorkey === 'error.bilheteJaCadastrado'){
                   AlertService.error('Bilhete já cadastrado para o jogador: ' + errorparam);  
                } else{
                   AlertService.error('Problema no cadastro do jogador');   
                }
            } else {
                AlertService.error('Problema no cadastro do jogador');   
            }
            
        }

    }
})();
