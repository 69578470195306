(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('UserManagementForceResetController', UserManagementForceResetController);

    UserManagementForceResetController.$inject = ['$uibModalInstance', 'entity', '$http'];

    function UserManagementForceResetController ($uibModalInstance, entity, $http) {
        var vm = this;

        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        
        vm.resetAccount = entity;
        vm.clear = clear;
        vm.confirmReset = confirmReset;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmReset () {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                
                $http.post('api/users/force-reset/'+vm.resetAccount.login, vm.resetAccount.password).then(function () {
                    vm.success = 'OK';
                    $uibModalInstance.close(true);
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }            
        }
    }
})();
