(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteEstabelecimentoController', LoteEstabelecimentoController);

        LoteEstabelecimentoController.$inject = ['$scope','$state', '$cookies', 'Concurso', 'Regional', 'LoteEstabelecimento', 'ParseLinks', 'AlertService', 'pagingParams', 
        'paginationConstants'];

    function LoteEstabelecimentoController($scope, $state, $cookies,  Concurso, Regional, LoteEstabelecimento, ParseLinks, AlertService, pagingParams, paginationConstants) {

        var vm = this;

        vm.lotebilhetes = [];
        vm.regionais = [];        
        vm.concursos = [];        
        vm.filtrar = filtrar;
        vm.limparFiltro = limparFiltro;
        vm.filtro = pagingParams.filtro;        

        vm.changeconcurso = changeconcurso;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.transitonTo = transitonTo;        
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = { last: 0 };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;

        $scope.$on("updateListLoteEstabelecimento",function(){
            filtrar();
         });

        Regional. queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
        });

         Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0) {
                if( vm.filtro.concurso == null){
                  selecionaConcursoPadrao();
                  changeconcurso();
                } else {
                    var idx = 0;
                    idx = _.findIndex(vm.concursos, ['id', vm.filtro.concurso.id]);
                    vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
                    changeconcurso();
                }
            }
        });
        function changeconcurso(){
            salvaConncursoCookie();
            loadAll();
        }

        
        function limparFiltro(){
            vm.filtro = {concurso: null, regionalId:null };
            loadAll();
        }
        function filtrar(){
            vm.page = 1;
            vm.links = { last: 0 };
            load(vm.filtro);
        }
        function loadAll () {
            load(vm.filtro);
        }
        function load(obj){
            if (vm.filtro && vm.filtro.concurso){
                var obj = {"concursoId": vm.filtro.concurso.id, "regionalId": vm.filtro.regionalId}; 
                obj.page = vm.page - 1;     
                obj.size = vm.itemsPerPage;
                obj.sort = sort();
                LoteEstabelecimento.queryPorConcursoRegional(obj, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }


            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;     
                vm.lotebilhetes = [];
                for (var i = 0; i < data.length; i++) {
                    vm.lotebilhetes.push(data[i]);
                }
                lotebilhetesWithRowSpan();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.regionais = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        vm.loteTracker = loteTracker;

        vm.labelConcurso = labelConcurso;
        function loteTracker(lote){
            console.log(lote);
            return lote.concursoId + lote.id + lote.tipo.id;
        }
        function labelConcurso(concid){
            return ('0000'+Math.round(concid/100)).slice(-4)+'/'+('00'+(concid%100)).slice(-2);
        }

        function lotebilhetesWithRowSpan(){
            var lotes  = vm.lotebilhetes;
            var objgrupos = {};
            for (var a=0; a < lotes.length; a++){
                var key = lotes[a].concurso.id + ''+ lotes[a].id;
                if(!objgrupos[key]){
                    objgrupos[key] = {span: 0, primeiro: lotes[a].bilheteInicial, soma: 0 };
                }
                objgrupos[key].span++;
                objgrupos[key].soma += lotes[a].tipo.valor * lotes[a].quantidade;
                if (lotes[a].bilheteInicial < objgrupos[key].primeiro){
                    objgrupos[key].primeiro = lotes[a].bilheteInicial;
                }
            }
            for (var a=0; a < lotes.length; a++){
                var key = lotes[a].concurso.id + ''+ lotes[a].id;
                lotes[a].rowspan = ((lotes[a].bilheteInicial === objgrupos[key].primeiro))? objgrupos[key].span: 0;
                lotes[a].valorTotal =  objgrupos[key].soma;
            }  
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }

        function transitonTo(statename, stateparams){
            $state.params.filtro = vm.filtro;
            $state.params.page = vm.page;
            $state.params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            //var idregional = (lotebilhete.regional)? lotebilhete.regional.id : null;
            //$state.transitionTo('lote-regional.changeowner',
            //        {concurso:lotebilhete.concurso, idlote:lotebilhete.id, idregional:idregional}
            //);
            $state.transitionTo(statename, stateparams);
        }        

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }             
    }
})();
