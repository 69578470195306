(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('TotalizacaoRegionalController', TotalizacaoRegionalController);

    TotalizacaoRegionalController.$inject = ['$timeout', '$scope', '$http', '$cookies',
        'Concurso', 'Regional', 'DistribuicaoBilhetes', 'Principal', 'AlertService'];

    function TotalizacaoRegionalController ($timeout, $scope, $http, $cookies,
        Concurso, Regional, DistribuicaoBilhetes, Principal, AlertService) {
        var vm = this;
        vm.totalizacao = [];
        vm.concursos = [];
        vm.tipos = [];
        vm.regionais = [];
        vm.filtro = {concurso: null, regional: null};
        vm.changeregional = changeregional;
        vm.changeconcurso = changeconcurso;
        vm.saveCSV = saveCSV;
        vm.requesting = false;

        vm.sumBy = function (a, b){
            return _.sumBy(a,b);
        }
        vm.calcPreco = function (t){
            return t.totalValidados  * t.concurso.tipo.valor; 
        }
        vm.calcComissao = function(t){
            return t.totalValidados  * t.concurso.tipo.valor * (t.regional.percentualRepasse / 100.0);
        }

        Regional.queryTodos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }                
            Concurso.queryshort().$promise.then(function(res){
                vm.concursos = res;
                if(vm.concursos.length > 0){
                    selecionaConcursoPadrao();
                    changeconcurso();
                }
            });            
        });

        Concurso.queryTiposBilhetes().$promise.then(function(res){
            vm.tipos = res;
        });



        function changeconcurso(){
            if (vm.filtro.concurso){}
            salvaConncursoCookie();
            vm.filtro.regional = null;
            changeregional();
        }
        function changeregional(){     
            loadAll();
        }
        function reset(){
            vm.totalizacao = [];
        }
        function saveCSV(){
            requestData(onSuccessSaveCSVRequest);
        }    
        function loadAll () {
            requestData(onSuccessSearchRequest);
        }

        function requestData (successFunction) {        
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            } 

            if (!vm.filtro.concurso && vm.filtro.regional ) {
                vm.requesting = true;
                DistribuicaoBilhetes.queryTendenciaTotalizacaoRegional({
                    regionalId: vm.filtro.regional.id
                }, successFunction, onError);                     
            } else if (vm.filtro.concurso && vm.filtro.regional){ 
                vm.requesting = true;
                DistribuicaoBilhetes.queryTotalizacaoRegional({
                    concursoId: vm.filtro.concurso.id,
                    regionalId: vm.filtro.regional.id
                }, function (data, headers) {        
                    var lst = [data];
                    successFunction(lst, headers)
                  }, onError);               
            } else if (vm.filtro.concurso) {
                vm.requesting = true;
                DistribuicaoBilhetes.queryTotalizacaoTodasRegional({
                    concursoId: vm.filtro.concurso.id,
                }, successFunction, onError);                
            } else {
                AlertService.error("Selecione um concurso e/ou regional");
                reset();
            }
                              
            function onError(error) {
                vm.requesting = false;
                AlertService.error(error.data.message);
            };             
        }
        function onSuccessSearchRequest(data, headers) { 
            vm.requesting = false;       
            vm.totalizacao = data;
        }        
        function onSuccessSaveCSVRequest(data, headers) {
            Principal.hasAuthority('PERM_VISUALIZ_TOTALIZACAO')
            .then(function (result) {
                vm.requesting = false;
                var hasAuthority = result;   
                var lista = data;
                _.sortBy(lista, ['regional.nome']);
                var content =  'Concurso;COD_Reg;Regional;Qtd.Dist.Regional;Qtd.Dist.Estabelecimentos;Qtd.Validados';
                if(hasAuthority) content += ';Valor;Percent.;Comissão;Faturamento';
                content += '\n';
              
                for (var i = 0; i < lista.length; i++) {
                    content += (lista[i].concurso.label).replace(/[;,\n]/g,'')+';';
                    content += lista[i].regional.id+';';
                    content += (lista[i].regional.nome).replace(/[;,\n]/g,'')+';';
                    content += lista[i].totalLotesRegional+';';
                    content += lista[i].totalLotesVenda+';';
                    content += lista[i].totalValidados;                  
                    if(hasAuthority){
                        content += ';"'+(''+(lista[i].totalValidados * lista[i].concurso.tipo.valor)).replace(/\./g,',') +'"';
                        content += ';'+(lista[i].regional.percentualRepasse);
                        content += ';"'+(''+(lista[i].totalValidados * lista[i].concurso.tipo.valor * (lista[i].regional.percentualRepasse/ 100.0))).replace(/\./g,',')+'"'; 
                        content += ';"'+(''+(lista[i].totalValidados * lista[i].concurso.tipo.valor * ((100 - lista[i].regional.percentualRepasse)/ 100.0))).replace(/\./g,',')+'"';           
                    } 
                    content +='\n';
                }
                content +=';;TOTAL;';
                content += vm.sumBy(vm.totalizacao, 'totalLotesRegional')+';';
                content += vm.sumBy(vm.totalizacao, 'totalLotesVenda')+';';                
                content += vm.sumBy(vm.totalizacao, 'totalValidados');
                if(hasAuthority){
                    content += ';"'+ (''+vm.sumBy(vm.totalizacao, vm.calcPreco)).replace(/\./g,',')+'"';
                    content += ';';
                    content += ';"'+ (''+vm.sumBy(vm.totalizacao, vm.calcComissao)).replace(/\./g,',')+'"';
                    content += ';"'+ (''+(vm.sumBy(vm.totalizacao, vm.calcPreco) - vm.sumBy(vm.totalizacao, vm.calcComissao))).replace(/\./g,',')+'"';                    
                };
                //var hiddenElement = document.createElement('a');
                //hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
                //hiddenElement.download = 'relatorio_totalizacao_regionais'+vm.filtro.concurso.id+'.csv';
                //document.body.appendChild(hiddenElement);
                //hiddenElement.click();
                
                var fileName = '';
                if (vm.filtro.regional.id)
                    fileName = fileName = 'relatorio_totalizacao_regional_'+vm.filtro.regional.nome.replace(/ /g,'_')+'.csv';
                else 
                    fileName = 'relatorio_totalizacao_regionais_'+vm.filtro.concurso.id+'.csv';
                var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
                var csvContentEncoded = textEncoder.encode([content]);
                var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
                saveAs(blob, fileName); 
            });
        }
        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }     
    }
})();
