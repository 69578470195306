(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('TotalizacaoLancamentoController', TotalizacaoLancamentoController);

        TotalizacaoLancamentoController.$inject = ['$timeout', '$scope', '$http', '$cookies',
        'Lancamento', 'Principal', 'AlertService', 'DateUtils'];

    function TotalizacaoLancamentoController ($timeout, $scope, $http, $cookies,
        Lancamento, Principal, AlertService, DateUtils) {
        var vm = this;
        vm.totalizacao = [];
        vm.filtro = {dataInicio: null, dataFim: null};
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.loadAll = loadAll;
        vm.saveCSV = saveCSV;
        vm.requesting = false;

        vm.sumBy = function (a, b){
            return _.sumBy(a,b).toFixed(2);
        }

        
        loadAll();

        function reset(){
            vm.totalizacao = [];
        }
        function saveCSV(){
            requestData(onSuccessSaveCSVRequest);
        }    
        function loadAll () {
            requestData(onSuccessSearchRequest);
        }

        function requestData (successFunction) {        
            if (vm.filtro.dataInicio && vm.filtro.dataFim) {
                vm.requesting = true;
                Lancamento.totalizacao({
                    datainicio: vm.filtro.dataInicio.toJSON().slice(0,10),
                    datafim: vm.filtro.dataFim.toJSON().slice(0,10)
                }, function (data, headers) {        
                    var lst = [data];
                    successFunction(lst, headers)
                  }, onError);               
            } else {
                reset();
            }
                              
            function onError(error) {
                vm.requesting = false;
                AlertService.error(error.data.message);
            };             
        }
        function onSuccessSearchRequest(data, headers) { 
            vm.requesting = false;       
            vm.totalizacao = data[0];
        }        
        function onSuccessSaveCSVRequest(data, headers) {
            Principal.hasAuthority('PERM_VISUALIZ_TOTALIZACAO')
            .then(function (result) {
                vm.requesting = false;
                var hasAuthority = result;   
                var lista = data[0];
                _.sortBy(lista, ['grupoLancamento.tipoOperacao.nome','grupoLancamento.nomeGrupo','descricao']);
                var content = 'Tipo Operação;Grupo;Valor Total\n';
                for (var i = 0; i < lista.length; i++) {
                    content += (lista[i].grupoLancamento.tipoOperacao.nome).replace(/[,;]/g,'')+';';
                    content += (lista[i].grupoLancamento.nomeGrupo).replace(/[,;]/g,'')+';';
                    content += '"'+(''+lista[i].valor).replace(/\./g,',') +'"\n';
                }
                content +=';TOTAL;';
                content += '"'+(''+vm.sumBy(vm.totalizacao, 'valor')).replace(/\./g,',') +'"\n';

                var fileName = 'relatorio_totalizacao_lancamentos.csv';
                var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
                var csvContentEncoded = textEncoder.encode([content]);
                var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
                saveAs(blob, fileName); 
            });
        }

        vm.datePickerOpenStatus.dataInicio = false;
        vm.datePickerOpenStatus.dataFim = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
                  
    }
})();
