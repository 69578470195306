(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .directive('jhSwitchTheme', function() {
            return {
            restrict: 'A',
            scope: { 
            },
            link: function (scope, element, attrs) {
                element.on('click',function(){
                    var bootswatchcss_elem  = document.getElementById("bootswatch-css");
                    bootswatchcss_elem.setAttribute("href", element.attr('data-theme-css'));
                    var themelink_elements = document.getElementsByClassName('theme-link');
                    for (var i = 0; i < themelink_elements.length; i++) {
                        themelink_elements[i].classList.remove('active');
                    }
                    element.parent().addClass("active");
                });
            }
        }
    });

})();
   