(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('BootswatchController', BootswatchController);

        BootswatchController.$inject = ['$scope', 'BootSwatchService', 'ProfileService'];

    function BootswatchController ($scope, BootSwatchService, ProfileService) {
        /*Get the list of availabel bootswatch themes*/
        ProfileService.getProfileInfo().then(function(response) {
            var bootswatchcss_elem  = document.getElementById("bootswatch-css");
            bootswatchcss_elem.setAttribute("title", response.Bootswatchtheme);
            BootSwatchService.get().then(function(themes) {
                $scope.themes = themes;
                $scope.themes.unshift({name:'Default',css:''});

                for (var a=0; a < $scope.themes.length; a++){
                    if (response.Bootswatchtheme === $scope.themes[a].name){
                       bootswatchcss_elem.setAttribute("href", $scope.themes[a].cssCdn);
                       $scope.themes[a].isdefault = 1;
                       break;
                    }
                }            
            });  
        });


      
    }
})();
