(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('AreaRegionalDetailController', AreaRegionalDetailController);

    AreaRegionalDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', '$http',
     'entity', 'Concurso', 'AreaRegional', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function AreaRegionalDetailController($scope, $rootScope, $state, $stateParams, previousState, $http, 
             entity, Concurso, AreaRegional, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.arearegional = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('sorteioApp:areaRegionalUpdate', function(event, result) {
            vm.arearegional = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
