(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('EstabelecimentoDialogController', EstabelecimentoDialogController);

    EstabelecimentoDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'AlertService', '$uibModalInstance', 'entity', 
                                'Estabelecimento', 'Regional', 'AreaRegional'];

    function EstabelecimentoDialogController ($timeout, $scope, $stateParams, AlertService, $uibModalInstance, entity, 
                                 Estabelecimento, Regional, AreaRegional) {
        var vm = this;

        vm.estabelecimento = entity;
        vm.regionais = Regional.queryAtivos();
        vm.areasregional = [];
        vm.clear = clear;
        vm.save = save;
        vm.editForm;
        vm.passwordDoNotMatch = null;
        vm.changeregional = changeregional;        

        if(!vm.estabelecimento.id || vm.estabelecimento.id < 1) {
           vm.estabelecimento.ativo = true;
        }
        changeregional ();
        function changeregional () {
            if (vm.estabelecimento.regional){
                AreaRegional.queryPorRegional({'regionalId': vm.estabelecimento.regional.id }).$promise.then(function(res){
                    vm.areasregional = _.orderBy(res, ['nome'], ['asc']);
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function nullfyEmptyProperty(field){
            if (!vm.estabelecimento[field] || (vm.estabelecimento[field] != null && 0 === vm.estabelecimento[field].length)) vm.estabelecimento[field] = null;
        }
        function save () {
            vm.isSaving = true;                
            nullfyEmptyProperty('telefone');
            nullfyEmptyProperty('logradouro');
            nullfyEmptyProperty('numero');
            nullfyEmptyProperty('cep');
            nullfyEmptyProperty('pontoReferencia');

            if (vm.estabelecimento.id !== null) {
                Estabelecimento.update(vm.estabelecimento, onSaveSuccess, onSaveError);
            } else {
                Estabelecimento.save(vm.estabelecimento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:estabelecimentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = '';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.sempermissao'){
                    msg = 'O usuário não tem permissão sobre a regional selecionada.';                    
                } else {
                    msg ="Verifique se existe outro estabelecimento padrão para a regional."; 
                }               } 
            AlertService.error(msg);                
        }


    }
})();
