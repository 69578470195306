(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('ConsultaStatusBilhetesController', ConsultaStatusBilhetesController);

        ConsultaStatusBilhetesController.$inject = ['$timeout', '$scope', '$stateParams',  '$http', '$state', '$cookies',
        'Concurso', 'Bilhete', 'DistribuicaoBilhetes', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function ConsultaStatusBilhetesController ($timeout, $scope, $stateParams,  $http, $state, $cookies,
       Concurso, Bilhete, DistribuicaoBilhetes, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.concursos = [];
        vm.bilhetes = [];
        vm.concurso_selecionado = null;
        vm.filtro = pagingParams.filtro;
        vm.changeconcurso = changeconcurso;
        vm.totalItems = 0;        
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.loadAll = loadAll;

        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true; 

        Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0) {
                if( vm.filtro.concurso == null){
                    selecionaConcursoPadrao();
                    changeconcurso();
                } else {
                    loadAll();
                }
            }
        });

        function changeconcurso(){
            salvaConncursoCookie();
            vm.filtro.numero = null;    
            loadAll();       
        }

        function loadAll () {
            if (vm.filtro.concurso == null || vm.filtro.numero == null){
                vm.bilhetes = [];
                vm.concurso_selecionado = null;
            } else {
                vm.concurso_selecionado = vm.filtro.concurso.label;
                Bilhete.consultar_todos({
                    concursoid: vm.filtro.concurso.id,
                    numinicio:vm.filtro.numero,
                    numfim:vm.filtro.numero,
                    page: 0,//pagingParams.page - 1,
                    size: 100,//vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
                buscar();
            }  

           function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                 vm.queryCount = vm.totalItems;     
                 vm.bilhetes = [];
                for (var i = 0; i < data.length; i++) {
                    vm.bilhetes.push(data[i]);
                }
            }

            function onError(error) {
                vm.concurso_selecionado = null;
                console.log(error.data.message)
                AlertService.error("Erro na consulta de bilhetes");
            }
        }

        function  buscar (){
            DistribuicaoBilhetes.queryLogDistribuicaoBilhetePorNumeracao(
                {'concursoId': vm.filtro.concurso.id,
                 'numero': vm.filtro.numero,
                 page: 0, size: 50})
            .$promise.then( function(data) {
               vm.logdistribuicao = data;
               console.log(data);
            }).catch(function(){
               vm.logdistribuicao = [];
               AlertService.error("Não foi possível coletar o log de distribuições");
            });
        }        

        function reset () {
            vm.page = 0;
            vm.filtro = {'concursoid' : null, 'numero' : null};
            vm.concurso_selecionado = null;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro
            });
        }

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }          
    }
})();
