(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteRegionalConfirmacaoController',LoteRegionalConfirmacaoController);

    LoteRegionalConfirmacaoController.$inject = ['$uibModalInstance', '$stateParams'];

    function LoteRegionalConfirmacaoController($uibModalInstance, $stateParams) {
        var vm = this;

        vm.lotebilhetes = $stateParams.entity;
        vm.confirm = confirm;
        vm.valorTotal = 0;
        vm.regional = null;
        vm.concurso = null;
        vm.id = null;

        if (vm.lotebilhetes){
            console.log('PARAMS %o', $stateParams.entity);
            for(var a=0; a< vm.lotebilhetes.length; ++a ){
                vm.valorTotal += vm.lotebilhetes[a].tipo.valor * vm.lotebilhetes[a].quantidade;
            }
            vm.regional = vm.lotebilhetes[0].regional;
            vm.concurso = vm.lotebilhetes[0].concurso;
            vm.id = vm.lotebilhetes[0].id;

        }


        function confirm (id) {
            $uibModalInstance.close(true);
            //$state.go('lote-regional', null, { reload: 'lote-regional' });
        }
    }
})();
