(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RegionalDialogController', RegionalDialogController);

    RegionalDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
     'entity', 'Regional', 'AlertService',];

    function RegionalDialogController ($timeout, $scope, $stateParams, $uibModalInstance, 
        entity, Regional, AlertService) {
        var vm = this;

        vm.regional = entity;
        vm.clear = clear;
        vm.save = save;
        vm.editForm;
        vm.passwordDoNotMatch = null;

        if(!vm.regional.id || vm.regional.id < 1){
           vm.regional.ativo = true;
           vm.regional.login = null;
           vm.regional.senha = null;
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function nullfyEmptyProperty(field){
            if (!vm.regional[field] || (vm.regional[field] != null && 0 === vm.regional[field].length)) vm.regional[field] = null;
        }
        function save () {
           nullfyEmptyProperty('senha');
           if (vm.regional.senha != null   &&
                vm.regional.senha !== vm.confirmaSenha) {
               console.log('senha diferente');
               vm.editForm.senha.$invalid = true;
               vm.editForm.senha.$error.different = true;
               vm.editForm.confirmPassword.$invalid = true;
               vm.editForm.confirmPassword.$error.different = true;
               vm.doNotMatch = 'ERROR';
            } else {
                vm.isSaving = true;
                nullfyEmptyProperty('responsavel');
                nullfyEmptyProperty('telefone');
                nullfyEmptyProperty('email');
                nullfyEmptyProperty('login');
                nullfyEmptyProperty('logradouro');
                nullfyEmptyProperty('numero');
                nullfyEmptyProperty('bairro');
                nullfyEmptyProperty('cidade'); 
                nullfyEmptyProperty('uf');                        
                nullfyEmptyProperty('cep');
                if (vm.regional.id !== null) {
                    Regional.update(vm.regional, onSaveSuccess, onSaveError);
                } else {
                    Regional.save(vm.regional, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:regionalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Problema na criação do regional!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.sempermissao'){
                    msg = 'Sem permissão para realizar a ação'; 
                } else if (errorkey === 'error.errocriarusuario'){
                    msg = 'Erro ao criar usuário da regional. '+ errorparam; 
                } 
            } 
            AlertService.error(msg);
        }


    }
})();
