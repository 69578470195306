(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('LoteEstabelecimentoDeleteController',LoteEstabelecimentoDeleteController);

    LoteEstabelecimentoDeleteController.$inject = ['$rootScope','$uibModalInstance', 'concurso', 
    'idlote', '$http', 'AlertService'];

    function LoteEstabelecimentoDeleteController($rootScope, $uibModalInstance, concurso,
         idlote, $http, AlertService) {
        var vm = this;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.idlote = idlote;
        vm.concurso = concurso;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            if (vm.concurso){
              $http.delete('api/lotevenda/exclui/'+vm.concurso.id+'/'+vm.idlote).then(
                function () {
                    AlertService.success('Lote de venda removido com sucesso');
                    $rootScope.$broadcast('updateListLoteEstabelecimento');
                    $uibModalInstance.close(true);
                }, function(response){
                    var msg = 'Erro na remoção do lote!';
                    if (response.status == 400){
                        var errorkey = response.headers('X-sorteioApp-error');
                        var errorparam = response.headers('X-sorteioApp-params');
                        
                        if (errorkey === 'error.sempermissao'){
                            msg = 'Sem permissão para a ação'; 
                        } else if (errorkey === 'error.bilhetevalidado'){
                            msg = 'Alguns bilhetes deste lote já foram validados.'; 
                        } else if (errorkey === 'error.loteinvalido'){
                            msg = 'Lote inválido! Para maiores detalhes, entre contato com o admin do sistema.';                   
                        }
                    } 
                    AlertService.error(msg);
                });       
           }         
        }
    }
})();
