(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sorteio', {
            parent: 'entity',
            url: '/sorteio',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'Página de Sorteios'
            },
            views: {
                'content@': {
                    templateUrl: 'app/sorteio/sorteios.html',
                    controller: 'SorteioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('sorteio.diario', {
            parent: 'sorteio',
            url: '/sorteio-diario/{id}/{label}',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'Sorteio Diário'
            },
            views: {
                'content@': {
                    templateUrl: 'app/sorteio/sorteio-diario.html',
                    controller: 'SorteioDiarioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', function($stateParams) {
                    console.log("PARAM %o", $stateParams);
                    return {id : $stateParams.id, label: $stateParams.label};
                }]                
            }
        })
        .state('sorteio.mensal', {
            parent: 'sorteio',
            url: '/sorteio-mensal/{id}',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'Sorteio Mensal'
            },
            views: {
                'content@': {
                    templateUrl: 'app/sorteio/sorteio-mensal.html',
                    controller: 'SorteioMensalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', function($stateParams) {
                    return {id : $stateParams.id, label: $stateParams.label};
                }]                
            }
        })
        .state('sorteio.diario-dialog', {
            parent: 'sorteio.diario',
            url: '/sorteio-diario-dialog',
            params: { elementos: null , concurso: null},
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/sorteio/sorteio-diario-dialog.html',
                    controller: 'SorteioDiarioDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'modal-sorteio-popup',
                    resolve: {
                        dados: function () {
                            console.log($stateParams);
                            return {elementos: $stateParams.elementos, concurso: $stateParams.concurso};
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        });        
    }

})();
