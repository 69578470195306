(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RelatorioDistribuicaoRegionalSemEstabelecimentoController', RelatorioDistribuicaoRegionalSemEstabelecimentoController);

    RelatorioDistribuicaoRegionalSemEstabelecimentoController.$inject = ['$timeout', '$scope', '$cookies', '$state',
        'Concurso', 'Regional', 'DistribuicaoBilhetes', 'Principal', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function RelatorioDistribuicaoRegionalSemEstabelecimentoController ($timeout, $scope, $cookies, $state,
       Concurso, Regional, DistribuicaoBilhetes, Principal, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.regionais = [];
        vm.concursos = [];
        vm.filtro = pagingParams.filtro;
        vm.changeconcurso = changeconcurso;
        vm.changeregional = changeregional;
        vm.lotebilhetes = [];
        vm.allLotes = [];
        vm.requesting = false;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.saveCSV = saveCSV;
        vm.search = search;        
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;        

        Regional.queryAtivos().$promise.then(function(res){
            vm.regionais = _.orderBy(res, ['nome'], ['asc']);
            if (vm.regionais.length > 1){
                vm.regionais.unshift({'id': -1, 'nome': "Todas Regionais"});
            }               
            Concurso.queryshort().$promise.then(function(res){
                vm.concursos = res;
                if(vm.concursos.length > 0){              
                    selecionaConcursoPadrao();
                    changeconcurso();
                    loadAll();
                }
            });    
        });          

        function changeconcurso(){
            salvaConncursoCookie();
            changeregional();
        }
        function changeregional(){             
            ;
        }

        function saveCSV(){
            requestData(onSuccessSaveCSVRequest, 0, 10000000);
        }    
        function search(){
            vm.page = 1;
            requestData(onSuccessSearchRequest, 0, vm.itemsPerPage);            
        }
        function loadAll () {
            requestData(onSuccessSearchRequest, vm.page - 1, vm.itemsPerPage);
        }

        function requestData (successFunction, startPage, pageSize) {
            if (vm.regionais.length == 1){
                vm.filtro.regional = vm.regionais[0];
            }                
            if (vm.filtro.regional) {
                vm.requesting = true;
                DistribuicaoBilhetes.queryDistribuicaoDisponivel({
                    concursoId: vm.filtro.concurso.id,
                    regionalId: (vm.filtro.regional.id>0)? vm.filtro.regional.id : null,
                    page: startPage,
                    size: pageSize,
                    sort: sort()                    
                }, successFunction, onError);
            } else {
                vm.page = 1;
                vm.totalItems = 1;
                vm.queryCount = 1;
                vm.links = {
                    last: 0
                };                
                vm.lotebilhetes = [];              
            }
            function sort() {
                //var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                //if (vm.predicate !== 'id') {
                //    result.push('id');
                //}
                return "regional_name";
            }            
            function onError(error) {
                vm.requesting = false;
                AlertService.error(error.data.message);
            }
        }
        function onSuccessSearchRequest(data, headers) {
            vm.requesting = false;
            vm.lotebilhetes = [];                 
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
            for (var i = 0; i < data.length ; i++) {
                vm.lotebilhetes.push(data[i]);
            }
        }

        function onSuccessSaveCSVRequest(data, headers) {     
            vm.requesting = false;        
            var lista = data;
            _.sortBy(lista, ['regional.nome', 'bilheteInicial']);
            var content = 'Concurso;Número Inicial;Número Final;Quantidade;';
            content += 'Regional\n';
            for (var i = 0; i < lista.length; i++) {
                content += (lista[i].concurso.label).replace(/[,;\n]/g,'')+';';
                content += (lista[i].bilheteInicial).replace(/[,;\n]/g,'')+';';
                content += (lista[i].bilheteFinal).replace(/[,;\n]/g,'')+';';
                content += lista[i].quantidade+';';
                content += (lista[i].regional.nome).replace(/[,;\n]/g,'')+'\n';
            }
            //var hiddenElement = document.createElement('a');
            //hiddenElement.href =  encodeURI('data:text/csv;charset=utf-8,' + content);
            //hiddenElement.download = 'relatorio_dist_sem_estabelecimento'+vm.filtro.concurso.id+'.csv';
            //document.body.appendChild(hiddenElement);
            //hiddenElement.click(); 
            var fileName = 'relatorio_dist_sem_estabelecimento'+vm.filtro.concurso.id+'.csv';
            var  textEncoder = new CustomTextEncoder('windows-1252', {NONSTANDARD_allowLegacyEncoding: true});              
            var csvContentEncoded = textEncoder.encode([content]);
            var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
            saveAs(blob, fileName);                                          
        }

        function reset () {
            vm.page = 1;
            vm.filtro.regional = null;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: 'id,asc',
                search: vm.currentSearch,
                filtro: vm.filtro                
            });
        }                

        function selecionaConcursoPadrao(){
            var concursoCookie = $cookies.getObject('concursoSelecionado');
            var idx = 0;
            if (concursoCookie){
                idx = _.findIndex(vm.concursos, ['id', concursoCookie.id]);
            }
            vm.filtro.concurso = vm.concursos[(idx<0)?0:idx];
        }
        function salvaConncursoCookie() {
            $cookies.putObject('concursoSelecionado', vm.filtro.concurso);
        }            
    }
})();
