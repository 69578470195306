(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('GrupoLancamentoDeleteController',GrupoLancamentoDeleteController);

    GrupoLancamentoDeleteController.$inject = ['$uibModalInstance', 'entity', 'GrupoLancamento'];

    function GrupoLancamentoDeleteController($uibModalInstance, entity, GrupoLancamento) {
        var vm = this;

        vm.grupolancamento = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GrupoLancamento.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
