(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('AreaRegionalDialogController', AreaRegionalDialogController);

    AreaRegionalDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'AlertService', '$uibModalInstance', 'entity', 
                                'AreaRegional', 'Regional'];

    function AreaRegionalDialogController ($timeout, $scope, $stateParams, AlertService, $uibModalInstance, entity, 
        AreaRegional, Regional) {
        var vm = this;

        vm.arearegional = entity;
        vm.regionais = Regional.queryAtivos();
        vm.clear = clear;
        vm.save = save;
        vm.editForm;
        vm.passwordDoNotMatch = null;        

        if(!vm.arearegional.id || vm.arearegional.id < 1) {
           vm.arearegional.ativo = true;
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function nullfyEmptyProperty(field){
            if (!vm.arearegional[field] || (vm.arearegional[field] != null && 0 === vm.arearegional[field].length)) vm.arearegional[field] = null;
        }
        function save () {
           nullfyEmptyProperty('senha');
           if (vm.arearegional.senha != null &&
                vm.arearegional.senha !== vm.confirmaSenha) {
               console.log('senha diferente');
               vm.editForm.senha.$invalid = true;
               vm.editForm.senha.$error.different = true;
               vm.editForm.confirmPassword.$invalid = true;
               vm.editForm.confirmPassword.$error.different = true;            
               vm.doNotMatch = 'ERROR';
            } else {            
                vm.isSaving = true;                
                nullfyEmptyProperty('email');
                nullfyEmptyProperty('login');
                nullfyEmptyProperty('senha');
                nullfyEmptyProperty('telefone');
                nullfyEmptyProperty('logradouro');
                nullfyEmptyProperty('numero');
                nullfyEmptyProperty('cep');
                nullfyEmptyProperty('pontoReferencia');

                if (vm.arearegional.id !== null) {
                    AreaRegional.update(vm.arearegional, onSaveSuccess, onSaveError);
                } else {
                    AreaRegional.save(vm.arearegional, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sorteioApp:areaRegionalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (response) {
            vm.isSaving = false;
            var msg = 'Problema na criação do regional!';
            if (response.status == 400){
                var errorkey = response.headers('X-sorteioApp-error');
                var errorparam = response.headers('X-sorteioApp-params');
                
                if (errorkey === 'error.sempermissao'){
                    msg = 'Sem permissão para realizar a ação'; 
                } else if (errorkey === 'error.errocriarusuario'){
                    msg = 'Erro ao criar usuário da regional. '+ errorparam; 
                } 
            } 
            AlertService.error(msg);
        }


    }
})();
