(function() {
    'use strict';
    angular
        .module('sorteioApp')
        .factory('Concurso', Concurso);

    Concurso.$inject = ['$resource', 'DateUtils'];

    function Concurso ($resource, DateUtils) {
        var resourceUrl =  'api/concursos/:id';

        var res = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataInicioVigencia = DateUtils.convertDateTimeFromServer(data.dataInicioVigencia);
                        data.dataFimVigencia = DateUtils.convertDateTimeFromServer(data.dataFimVigencia);
                        data.dataSorteio = DateUtils.convertDateTimeFromServer(data.dataSorteio);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataInicioVigencia = DateUtils.convertDateTimeFromServer(copy.dataInicioVigencia);
                    copy.dataFimVigencia = DateUtils.convertDateTimeFromServer(copy.dataFimVigencia);
                    copy.dataSorteio = DateUtils.convertDateTimeFromServer(copy.dataSorteio);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataInicioVigencia = DateUtils.convertDateTimeFromServer(copy.dataInicioVigencia);
                    copy.dataFimVigencia = DateUtils.convertDateTimeFromServer(copy.dataFimVigencia);
                    copy.dataSorteio = DateUtils.convertDateTimeFromServer(copy.dataSorteio);
                    return angular.toJson(copy);
                }
            }
        });

        res.queryTiposBilhetes = (function (){
            var resourceTiposUrl =  'api/tiposbilhete';
            var resTipos = $resource(resourceTiposUrl, {}, {
                       'query': { method: 'GET', isArray: true}});
            return resTipos.query;
        })();

        res.queryshort = (function (){
            var resTipos = $resource( 'api/concursos-short', {}, {
                       'query': { method: 'GET', isArray: true}});
            return resTipos.query;
        })();
        res.querynaosinc = (function (){
            var resTipos = $resource( 'api/concursos-naosinc', {}, {
                       'query': { method: 'GET', isArray: true}});
            return resTipos.query;
        })();
        return res;
    }
})();
