(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('distribuicao-bilhetes', {
            parent: 'bilhete',
            url: '/distribuicao-bilhetes?page&sort',
            data: {
                authorities: ['PERM_CONFECCAO_LOTES'],
                pageTitle: 'sorteioApp.distribuicao-bilhetes.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribuicao-bilhetes/distribuicao-bilhetes.html',
                    controller: 'DistribuicaoBilhetesController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('distribuicao-bilhetes');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('historico-distribuicao-bilhete', {
            parent: 'bilhete',
            url: '/historico-distribuicao-bilhete',
            data: {
                authorities: ['PERM_CONFECCAO_LOTES'],
                pageTitle: 'sorteioApp.hist_distribuicao-bilhetes.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribuicao-bilhetes/hist_distribuicao-bilhetes.html',
                    controller: 'HistoricoDistribuicaoBilhetesController',
                    controllerAs: 'vm'
                }
            },params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                filtro: {concurso : null, regional : null}                
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        filtro: $stateParams.filtro,                        
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hist_distribuicao-bilhetes');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })           
        ;
    }

})();
