(function() {
    'use strict';

    angular
        .module('sorteioApp')
        .controller('RegionalDetailController', RegionalDetailController);

    RegionalDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', '$http', 
        'entity', 'Concurso', 'Regional', 'DistribuicaoBilhetes', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function RegionalDetailController($scope, $rootScope, $state, $stateParams, previousState, $http, entity, 
             Concurso, Regional, DistribuicaoBilhetes, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.regional = entity;
        vm.concursos = [];
       vm.filtro = {concurso: null};
        vm.lotebilhetes = [];
        vm.previousState = previousState.name;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.totalItems = 0;
        vm.queryCount = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;        

        $scope.$on('$destroy', unsubscribe);
        var unsubscribe = $rootScope.$on('sorteioApp:regionalUpdate', function(event, result) {
            vm.regional = result;
        });        
         Concurso.queryshort().$promise.then(function(res){
            vm.concursos = res;
            if(vm.concursos.length > 0 && vm.filtro.concurso == null){
                vm.filtro.concurso=vm.concursos[0];
                changeconcurso();
            }
        });

        function changeconcurso(){
            loadAll ();
        } 


        function loadAll () {
            if (vm.concursos.length == 1){
                vm.filtro.concurso = vm.concursos[0];
            }            
            DistribuicaoBilhetes.queryDistribuicaoRegional({
                concursoId: vm.filtro.concurso.id,
                regionalId: vm.regional.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                console.log("DATA %o %o", data ,headers);
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;     
                vm.lotebilhetes = data;     
                lotebilhetesWithRowSpan();         
            }

            function onError(error) {
                log.console(error.data.message);
            }
        }

        function lotebilhetesWithRowSpan(){
            var lotes  = vm.lotebilhetes;
            var objgrupos = {};
            for (var a=0; a < lotes.length; a++){
                var key = lotes[a].concurso.id + ''+ lotes[a].id;
                if(!objgrupos[key]){
                    objgrupos[key] = {span: 0, primeiro: lotes[a].bilheteInicial };
                }
                objgrupos[key].span++;
                if (lotes[a].bilheteInicial < objgrupos[key].primeiro){
                    objgrupos[key].primeiro = lotes[a].bilheteInicial;
                }
            }
            console.log("OBJGR %o", objgrupos);
            for (var a=0; a < lotes.length; a++){
                var key = lotes[a].concurso.id + ''+ lotes[a].id;
                lotes[a].rowspan = ((lotes[a].bilheteInicial === objgrupos[key].primeiro))? objgrupos[key].span: 0;
                //lotes[a].key = key; // +''+lotes[a].tipo.id;
            }  
            console.log('teste %o', lotes);
        }

        function reset () {
            vm.page = 0;
            vm.filtro.concurso = null;
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition () {
            console.log('STATE %o', $state);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filtro: vm.filtro,
                id : vm.regional.id
            });
        }       
    }
})();
